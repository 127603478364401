import { Flex } from '@chakra-ui/react'

import VantageLoader from '@/components/atoms/VantageLoader'

const KeycloakLoadingScreen = () => (
  <Flex
    width='100vw'
    justifyContent='center'
    alignItems='center'
    height='100vh'
  >
    <VantageLoader />
  </Flex>
)

export default KeycloakLoadingScreen
