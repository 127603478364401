const bgColors = [
  'russianViolet.500',
  'cyan.500',
  'blue.600',
  'teal.500',
  'pink.500',
  'orange.500',
  'yellow.500',
  'green.600',
]

// Modified FNV-1a hashing algorithm with different prime number and offset value
const stringToNumber = (str: string) => {
  let hash = 2166136261 // FNV offset basis
  const prime = 16777 // FNV prime
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i)
    hash *= prime
  }
  return hash
}

const getAvatarFallbackBgColor = (name: `${string} ${string}` | string) => {
  const number = name
    .trim()
    .toLowerCase()
    .split(' ')
    .filter(Boolean) // Remove any empty name parts
    .reduce((acc, curr) => acc + stringToNumber(curr), 0)

  const index = Math.abs(number) % bgColors.length // Use modulus operator to prevent out of bounds
  return bgColors[index]
}

export default getAvatarFallbackBgColor
