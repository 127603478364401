import { Flex, Box, VStack, Icon, Text } from '@chakra-ui/react'
import { BiGhost } from 'react-icons/bi'

const TableNoData = () => (
  <Flex justify='center' alignItems='center' h='40vh'>
    <VStack>
      <Icon as={BiGhost} color='fluorescentBlue.500' boxSize='40px' />
      <Box textAlign='center'>
        <Text fontSize='lg' fontWeight='semibold' color='gray.600'>
          No data yet
        </Text>
        <Text fontSize='xs' color='gray.500'>
          Oops! there's no data to display here just yet...
        </Text>
      </Box>
    </VStack>
  </Flex>
)

export default TableNoData
