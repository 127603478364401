import { ErrorToast, SuccessToast } from '@/components/molecules'
import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'

const useToasts = ({
  errorDescription,
  successDescription,
  errorTitle = 'Error',
  errorDescriptionNotAllowed = 'You are not allowed to perform this action.',
}: {
  errorTitle?: string
  errorDescription?: string
  successDescription?: string
  errorDescriptionNotAllowed?: string
}) => {
  const toast = useToast()
  const renderErrorToast = useCallback(
    (errorCode?: number) => {
      toast({
        position: 'top',
        render: () => (
          <ErrorToast
            title={errorTitle}
            description={
              errorCode === 403 ? errorDescriptionNotAllowed : errorDescription
            }
          />
        ),
      })
    },
    [toast, errorDescription, errorTitle, errorDescriptionNotAllowed]
  )

  const renderSuccessToast = useCallback(() => {
    toast({
      position: 'top',
      render: () => <SuccessToast title={successDescription} />,
    })
  }, [toast, successDescription])

  return {
    renderErrorToast,
    renderSuccessToast,
  }
}

export default useToasts
