import { components, OptionProps, GroupBase } from 'react-select'
import { BoldQuery } from '@/components/atoms'
import { chakra } from '@chakra-ui/react'

export type AsyncOption = OptionProps<any, boolean, GroupBase<any>>

const Option = ({ children, ...props }: AsyncOption) => (
  <components.Option {...props}>
    <chakra.span>
      {props.selectProps.inputValue ? (
        <BoldQuery
          query={props.selectProps.inputValue}
          str={props.data.label}
        />
      ) : (
        props.data.label
      )}
    </chakra.span>
  </components.Option>
)

export default Option
