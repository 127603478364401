import { useCallback, useEffect, useState } from 'react'
import { setOrganizationDetails } from '@/store/guardianSlice'
import { Axios } from '@/commons/helpers'
import useAppDispatch from './useAppDispatch'
import useKeycloak from './useKeycloak'

const useAppInit = () => {
  // hooks
  const dispatch = useAppDispatch()
  const [isAppInitializing, setIsAppInitializing] = useState(true)
  const { hasOrganizationInToken, isAuthenticated } = useKeycloak()

  const getAdditionalInformation = useCallback(async () => {
    const organizationInfo = await Axios.get(
      '/admin/management/organizations/my'
    )
      .then(({ data }) => data)
      .catch((error) => {
        console.error(error)
      })
    if (organizationInfo) {
      dispatch(setOrganizationDetails(organizationInfo))
    }
    setIsAppInitializing(false)
  }, [dispatch])

  // fetch additional data, such as company info, etc.
  // and triggers the app to render the first time
  useEffect(() => {
    if (!isAuthenticated) return
    if (hasOrganizationInToken) getAdditionalInformation()
    else setIsAppInitializing(false)
  }, [
    dispatch,
    getAdditionalInformation,
    hasOrganizationInToken,
    isAuthenticated,
  ])

  return {
    isAppInitializing,
  }
}

export default useAppInit
