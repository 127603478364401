import { Text, TextProps, Stack } from '@chakra-ui/react'

type TextListProps = {
  label?: string
  labelTextProps?: TextProps
  value?: string | number
  valueTextProps?: TextProps
  isParagraph?: boolean
  overflowEllipsis?: boolean
}

const makeValueStyle = (
  valueTextProps?: TextProps,
  overflowEllipsis?: boolean
) => {
  let valueTextPropsCopy = valueTextProps ?? {}
  if (overflowEllipsis) {
    valueTextPropsCopy.overflow = 'hidden'
    valueTextPropsCopy.textOverflow = 'ellipsis'
    valueTextPropsCopy.whiteSpace = 'nowrap'
  }

  return valueTextPropsCopy
}

const TextFieldComponent = ({
  isParagraph,
  label,
  labelTextProps,
  overflowEllipsis,
  value,
  valueTextProps,
}: TextListProps) => (
  <Stack
    spacing={isParagraph ? 1 : 2}
    direction={isParagraph ? 'column' : 'row'}
    lineHeight={5}
  >
    {label && (
      <Text
        fontSize='sm'
        color='black'
        fontWeight='semibold'
        {...labelTextProps}
      >
        {label}:
      </Text>
    )}
    <Text
      fontSize='sm'
      color='gray.600'
      {...makeValueStyle(valueTextProps, overflowEllipsis)}
    >
      {value}
    </Text>
  </Stack>
)

export default TextFieldComponent
