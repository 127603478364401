const styles = {
  global: {
    html: {
      margin: '0px',
      height: '100%',
      width: '100%',
    },
    body: {
      backgroundColor: 'mainBackground',
      margin: 0,
      minHeight: '100%',
      width: '100%',
    },
  },
}

export default styles
