import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'

import { useKeycloak } from '@/hooks'
import { fetchWatchers } from './services'
import { makeAlertsFromWatchers } from './utils'

const useNotifications = () => {
  const { hasOrganizationInToken, user } = useKeycloak()
  const email = user?.profile?.email
  const { data: watchers, refetch } = useQuery(
    'watchers',
    () => fetchWatchers(email),
    {
      enabled: hasOrganizationInToken && !!email,
    }
  )

  /* pooling the api for new notifications, this should be replace by a subscription method */
  useEffect(() => {
    if (hasOrganizationInToken) {
      setInterval(() => {
        refetch()
      }, 30 * 1000)
    }
  }, [refetch, hasOrganizationInToken])

  const alerts = useMemo(() => makeAlertsFromWatchers(watchers), [watchers])
  const uncheckedAlertsQtd = alerts?.filter(({ checked }) => !checked).length

  return {
    alerts,
    uncheckedAlertsQtd,
    refetchNotifications: refetch,
  }
}

export default useNotifications
