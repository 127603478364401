import { theme } from '@chakra-ui/react'
import { BoxModel } from '@chakra-ui/utils'
import { StylesConfig, GroupBase, OptionProps } from 'react-select'
import extendedThemeColors from '@/theme/colors'
import { SelectOption } from '@/commons/types'

type MakeStyles = (
  dimensions?: BoxModel | null
) => StylesConfig<any, boolean, GroupBase<any>>

const getOptionBackgroundColor = (
  state: OptionProps<SelectOption, boolean, GroupBase<SelectOption>>
) => {
  if (state.isSelected) {
    return extendedThemeColors.gray[200]
  }
  if (state.isFocused) {
    return extendedThemeColors.gray[100]
  }
  return extendedThemeColors.white
}

const makeStyles: MakeStyles = () => ({
  container: (styles) => ({
    ...styles,
    // 38 + 2 from border = 40
    height: '38px',
    width: '100%',
  }),
  control: (styles, state) => {
    return {
      ...styles,
      '&:hover': {
        borderColor: extendedThemeColors.transparent,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      },
      backgroundColor: state.isDisabled
        ? extendedThemeColors.gray[200]
        : extendedThemeColors.transparent,
      borderColor: extendedThemeColors.transparent,
      borderRadius: '8px',
      borderWidth: '0px',
      boxShadow: 'none',
      color: extendedThemeColors.gray[700],
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      fontSize: theme.fontSizes.sm,
      opacity: state.isDisabled ? 0.5 : 1,
    }
  },
  valueContainer: (styles) => ({
    ...styles,
    paddingInlineStart: theme.space[3],
    paddingInlineEnd: theme.space[3],
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 10000,
    position: 'absolute',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
    fontSize: theme.fontSizes.sm,
    color: extendedThemeColors.gray[700],
  }),
  option: (styles, state) => ({
    ...styles,
    '&:hover': {
      backgroundColor: getOptionBackgroundColor(state),
      cursor: 'pointer',
    },
    backgroundColor: getOptionBackgroundColor(state),
    fontSize: theme.fontSizes.sm,
    color: extendedThemeColors.iris[500],
    fontWeight: state.isSelected
      ? theme.fontWeights.semibold
      : theme.fontWeights.normal,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    display: state.isFocused ? 'none' : 'block',
  }),
})

export default makeStyles
