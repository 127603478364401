// todo import the file in the right way when the bug is fixed ref: https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import VantageLoaderSVG from '!file-loader!./VantageLoader.svg'
import { Box, Image } from '@chakra-ui/react'

const VantageLoader = () => (
  <Box height='2.75rem' width='3.75rem'>
    <Image src={VantageLoaderSVG} alt='VantageLoader' />
  </Box>
)
export default VantageLoader
