import { chakra, HStack, Link, VStack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

type FooterProps = {
  isHomePage: boolean
}

const Footer = ({ isHomePage }: FooterProps) => (
  <VStack alignItems='center' width='100%' spacing={3}>
    <chakra.hr
      color='gray.100'
      width='100%'
      display={isHomePage ? 'none' : 'block'}
    />
    <HStack fontSize='xs'>
      <chakra.span color={isHomePage ? 'iris.200' : 'gray.600'}>
        Copyright © {new Date().getFullYear()} Omnivector - All rights reserved
      </chakra.span>
      <chakra.span color='gray.400'>•</chakra.span>
      <Link
        as={RouterLink}
        to='/privacy-policy'
        color={isHomePage ? 'white' : 'iris.500'}
        fontWeight='semibold'
      >
        Privacy Policy
      </Link>
    </HStack>
  </VStack>
)

export default Footer
