import {
  Button,
  chakra,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

import ActionButtons from '@/components/atoms/ActionButtons'

type CodeEditorDrawerUnsavedModalProps = {
  filename: string
  isOpen: boolean
  isSubmitting: boolean
  onClose: () => void
  onResetChanges: () => void
  onSaveFile: () => void
}

const CodeEditorDrawerUnsavedModal = ({
  filename,
  isOpen,
  isSubmitting,
  onClose,
  onResetChanges,
  onSaveFile,
}: CodeEditorDrawerUnsavedModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='512px'>
        <ModalHeader paddingBottom={4}>
          <HStack justifyContent='space-between'>
            <chakra.span>You have unsaved changes.</chakra.span>
            <IconButton
              aria-label='close modal'
              icon={<Icon as={BiX} boxSize='18px' />}
              onClick={onClose}
              variant='modal-close-button'
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Text>
            What do you want to do regarding changes made to the following file?
          </Text>
          <Text fontWeight='bold' marginTop={4}>
            {filename}
          </Text>
        </ModalBody>
        <ModalFooter paddingTop={4}>
          <HStack justifyContent='space-between' width='100%'>
            <Button onClick={onClose} variant='outline'>
              Keep editing
            </Button>
            <ActionButtons
              cancelLabel='Discard Changes'
              cancelVariant='danger-ghost'
              isSubmitting={isSubmitting}
              onClickCancel={onResetChanges}
              onClickSubmit={onSaveFile}
              submitLabel='Save Changes'
              submitType='button'
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CodeEditorDrawerUnsavedModal
