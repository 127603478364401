import { components, ValueContainerProps, GroupBase } from 'react-select'
import { HStack, Icon } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<any, boolean, GroupBase<any>>) => {
  return (
    <components.ValueContainer {...props}>
      <HStack>
        <Icon as={BiSearch} color='gray.400' boxSize='16px' />
        {children}
      </HStack>
    </components.ValueContainer>
  )
}

export default ValueContainer
