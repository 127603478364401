import { useLayoutEffect } from 'react'
import useThrottle from './useThrottle'

const useScrollOnDiv = (
  divRef: React.RefObject<HTMLDivElement> | undefined | null,
  callback: (...args: any) => void
) => {
  const throttledCallback = useThrottle(callback, 200)

  useLayoutEffect(() => {
    if (!divRef) return
    const divElement = divRef.current
    if (divElement) {
      const handleScroll = () => {
        throttledCallback()
      }

      divElement.addEventListener('scroll', handleScroll)

      return () => {
        divElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [divRef, throttledCallback, callback])

  return divRef
}

export default useScrollOnDiv
