import { theme } from '@chakra-ui/react'
import { StylesConfig, GroupBase, OptionProps } from 'react-select'
import extendedThemeColors from '@/theme/colors'

import { Option } from '.'

const getOptionBackgroundColor = (
  state: OptionProps<Option, boolean, GroupBase<Option>>
) => {
  if (state.isSelected) {
    return extendedThemeColors.gray[200]
  }
  if (state.isFocused) {
    return extendedThemeColors.gray[100]
  }
  return extendedThemeColors.white
}

const makeStyles: StylesConfig<any, boolean, GroupBase<any>> = {
  container: (styles) => ({
    ...styles,
    height: '40px',
    width: '100%',
  }),
  control: (styles) => {
    return {
      ...styles,
      '&:hover': {
        borderColor: extendedThemeColors.transparent,
      },
      borderColor: extendedThemeColors.transparent,
      borderWidth: '0px',
      backgroundColor: extendedThemeColors.transparent,
      borderRadius: '8px',
      fontSize: theme.fontSizes.sm,
      color: extendedThemeColors.gray[700],
      boxShadow: 'none',
      cursor: 'pointer',
    }
  },
  valueContainer: (styles) => ({
    ...styles,
    paddingInlineStart: theme.space[3],
    paddingInlineEnd: theme.space[3],
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 10000,
    position: 'absolute',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
    fontSize: theme.fontSizes.sm,
    color: extendedThemeColors.gray[700],
  }),
  option: (styles, state) => ({
    ...styles,
    '&:hover': {
      backgroundColor: getOptionBackgroundColor(state),
      cursor: 'pointer',
    },
    backgroundColor: getOptionBackgroundColor(state),
    fontSize: theme.fontSizes.sm,
    color: extendedThemeColors.iris[500],
    fontWeight: state.isSelected
      ? theme.fontWeights.semibold
      : theme.fontWeights.normal,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  loadingMessage: (base) => ({
    ...base,
    fontSize: theme.fontSizes.sm,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: theme.fontSizes.sm,
  }),
}

export default makeStyles
