import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'
import { chakra, keyframes, TextProps } from '@chakra-ui/react'

type SkeletonSpanProps = {
  children: React.ReactNode
} & TextProps

const skeletonAnimation = keyframes`
  0% {
    border-color: ${getColorWithOpacity('gray.50')};
    background: ${getColorWithOpacity('gray.50')};
  }
  100% {
    border-color: ${getColorWithOpacity('gray.200')};
    background: ${getColorWithOpacity('gray.200')};
  }`

const SkeletonSpan = ({ children, ...props }: SkeletonSpanProps) => (
  <chakra.span
    animation={`0.8s linear infinite alternate ${skeletonAnimation}`}
    borderRadius='4px'
    color='transparent'
    lineHeight='0px'
    paddingTop='6px'
    paddingBottom='9px'
    {...props}
  >
    {children}
  </chakra.span>
)

export default SkeletonSpan
