import { Box, HStack, Icon, VStack, chakra } from '@chakra-ui/react'
import { BsExclamationCircleFill } from 'react-icons/bs'

type ErrorToastProps = {
  title?: string
  description?: string
}

const ErrorToast = ({ description, title }: ErrorToastProps) => (
  <Box
    backgroundColor='red.100'
    boxShadow='base'
    borderRadius='8px'
    paddingX={4}
    paddingY={3}
  >
    <HStack spacing={3} alignItems='flex-start'>
      <Icon
        marginTop='3.5px'
        as={BsExclamationCircleFill}
        boxSize='15px'
        color='red.500'
      />
      <VStack alignItems='flex-start' spacing={0}>
        <chakra.span
          lineHeight='24px'
          color='gray.700'
          fontSize='sm'
          fontWeight='bold'
        >
          {title}
        </chakra.span>
        <chakra.span lineHeight='24px' color='gray.700' fontSize='sm'>
          {description}
        </chakra.span>
      </VStack>
    </HStack>
  </Box>
)

export default ErrorToast
