import { Link as RouterLink } from 'react-router-dom'
import { chakra, HStack, Icon, MenuItem } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { useKeycloak } from '@/hooks'

type MenuItemLinkProps = {
  children: React.ReactNode
  icon: IconType
  to: string
  roles?: string[]
}

const BaseComponent = ({ children, icon, to }: MenuItemLinkProps) => (
  <MenuItem
    _active={{
      backgroundColor: 'transparent',
      fontWeight: 'semibold',
      color: 'gray.600',
    }}
    _hover={{
      backgroundColor: 'gray.100',
    }}
    as={RouterLink}
    to={to}
    color='iris.500'
    fontSize='sm'
    borderRadius='8px'
  >
    <HStack spacing={3.5}>
      <Icon as={icon} boxSize='18px' />
      <chakra.span>{children}</chakra.span>
    </HStack>
  </MenuItem>
)

const MenuItemLink = ({ roles, ...props }: MenuItemLinkProps) => {
  const { hasRolesInToken } = useKeycloak()
  return roles ? (
    hasRolesInToken(roles) ? (
      <BaseComponent {...props} />
    ) : null
  ) : (
    <BaseComponent {...props} />
  )
}
export default MenuItemLink
