import React from 'react'
import { FormikProps, FormikValues } from 'formik'

import { isEqual } from 'lodash'
import { IRowComponentProps } from '../..'
import FieldMultiInputsDefaultRow from '../FieldInputDefaultRow'

interface InnerFieldMultiInputsProps<
  FormValues extends FormikValues,
  InputType
> {
  allowRemoveAll?: boolean
  formProps: FormikProps<FormValues>
  remove: (index: number) => void
  hideLineRemoveButton?: boolean
  name: string
  push: (value: InputType) => void
  RowComponent: React.FC<IRowComponentProps<InputType>>
}

export type MultiInputTouched<InputType> = InputType extends Object
  ? { [key in keyof InputType]: boolean }
  : boolean
export type MultiInputErrors<InputType> = InputType extends Object
  ? { [key in keyof InputType]: string }
  : string

const InnerFieldMultiInputs = <FormValues extends FormikValues, InputType>({
  allowRemoveAll,
  formProps,
  hideLineRemoveButton,
  name,
  push,
  remove,
  RowComponent,
}: InnerFieldMultiInputsProps<FormValues, InputType>) => {
  const { values, errors, touched } = formProps

  return (
    <>
      {values[name].map(
        (input: InputType, index: number, original: InputType[]) => {
          const lineTouched = (
            touched[name] as MultiInputTouched<InputType>[]
          )?.[index]
          const lineErrors = lineTouched
            ? (errors?.[name] as MultiInputErrors<InputType>[])?.[index]
            : undefined
          return hideLineRemoveButton ? (
            <RowComponent
              fieldValues={original}
              index={index}
              key={index}
              lineErrors={lineErrors}
              push={push}
              remove={remove}
            />
          ) : (
            <FieldMultiInputsDefaultRow<InputType>
              allowRemoveAll={allowRemoveAll}
              key={index}
              index={index}
              remove={remove}
              RowComponent={RowComponent}
              original={original}
              lineErrors={lineErrors}
              push={push}
            />
          )
        }
      )}
    </>
  )
}

export type { InnerFieldMultiInputsProps }
export default React.memo(
  InnerFieldMultiInputs,
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.formProps.values[prevProps.name].length ===
      nextProps.formProps.values[nextProps.name].length &&
    isEqual(
      prevProps.formProps.errors[prevProps.name],
      nextProps.formProps.errors[nextProps.name]
    )
) as typeof InnerFieldMultiInputs
