import React from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Input,
  VStack,
} from '@chakra-ui/react'

import { FieldBaseProps } from '@/commons/types'
import { ActionButtons } from '@/components/atoms'

export interface CreateOptionInputProps extends FieldBaseProps {
  createNewOptionLabel: string
  createNewOptionService: () => void
  error: Error | null
  inputValue: string
  isCreatingNewOption: boolean
  onCancelCreateOption: () => void
  onChangeCreateInput: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CreateOptionInput = ({
  createNewOptionLabel,
  createNewOptionService,
  error,
  inputValue,
  isCreatingNewOption,
  name,
  onCancelCreateOption,
  onChangeCreateInput,
}: CreateOptionInputProps) => (
  <VStack alignItems='flex-end'>
    <Input
      autoFocus
      isInvalid={!!error}
      name={`${name}_create_input`}
      onChange={onChangeCreateInput}
      value={inputValue}
    />
    {!!error && (
      <Alert
        status='error'
        alignItems='center'
        backgroundColor='red.50'
        textAlign='center'
        lineHeight='24px'
      >
        <AlertIcon boxSize='18px' color='red.500' />
        <AlertDescription
          fontSize='sm'
          fontWeight='normal'
          verticalAlign='middle'
        >
          {error.message ?? 'Error while creating new option.'}
        </AlertDescription>
      </Alert>
    )}
    <ActionButtons
      isDisabled={inputValue === ''}
      isSubmitting={isCreatingNewOption}
      onClickCancel={onCancelCreateOption}
      onClickSubmit={createNewOptionService}
      submitLabel={createNewOptionLabel}
      submitType='button'
    />
  </VStack>
)

export default CreateOptionInput
