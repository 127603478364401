/**
 * Returns the first and last name of a person as a string.
 * @param name - The person's full name.
 * @returns A string containing the first and last name,
 * or just the first name if the name string only contains one name.
 */

const formatNames = (name: string | undefined) => {
  if (!name) return ''
  const names = name.trim().split(' ')
  if (names.length === 1) {
    return name
  }
  return `${names[0]} ${names[names.length - 1]}`
}

export default formatNames
