import { useState, useRef, useCallback } from 'react'

const useDebouncedInput = (
  timeoutTime: number = 300,
  onChange?: (value: string) => string
) => {
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useRef<NodeJS.Timeout>()

  const handleInputChange = useCallback(
    (value: string) => {
      if (onChange) setInputValue(() => onChange(value))
      else setInputValue(value)
    },
    [onChange]
  )

  const onDebouncedInputChange = useCallback(
    (value) => {
      // clears the previous timeout
      if (debouncedInputValue.current) clearTimeout(debouncedInputValue.current)

      // add debounce time
      debouncedInputValue.current = setTimeout(
        () => handleInputChange(value),
        timeoutTime
      )
    },
    [timeoutTime, handleInputChange]
  )

  return { inputValue, onDebouncedInputChange }
}

export default useDebouncedInput
