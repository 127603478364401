import React, { lazy, Suspense } from 'react'
import { Flex } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from 'react-router-dom'

import { useAppAuthEffects, useAppInit, useKeycloak } from '@/hooks'

import VantageLoader from '@/components/atoms/VantageLoader'
import Page404 from '@/components/molecules/Page404'
import KeycloakLoadingScreen from '@/components/molecules/KeycloakLoadingScreen'
import Sidebar from '@/components/templates/Sidebar'

const Admin = lazy(() => import('@/modules/Private/Admin'))
const Cluster = lazy(() => import('@/modules/Private/Cluster'))
const Home = lazy(() => import('@/modules/Private/Home'))
const Jobs = lazy(() => import('@/modules/Private/Jobs'))
const LicenseManager = lazy(() => import('@/modules/Private/LicenseManager'))
const Storage = lazy(() => import('@/modules/Private/Storage'))
const Support = lazy(() => import('@/modules/Private/Support'))
const PrivacyPolicy = lazy(() => import('@/modules/Private/PrivacyPolicy'))

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  path: string
  exact?: boolean
}

const PrivateRoute = ({ component, path, ...args }: PrivateRouteProps) => {
  const { isAuthenticated } = useKeycloak()
  return isAuthenticated ? (
    <Route component={component} path={path} {...args} />
  ) : null
}

const AdminRoute = ({ component, path, ...args }: PrivateRouteProps) => {
  const { isUserAdmin, isAuthenticated } = useKeycloak()
  return isAuthenticated && isUserAdmin ? (
    <Route component={component} path={path} {...args} />
  ) : null
}

const PrivateRouter = () => {
  const { isAppInitializing } = useAppInit()
  const { hasOrganizationInToken, isAuthenticated } = useKeycloak()
  useAppAuthEffects()

  if (isAppInitializing) return <KeycloakLoadingScreen />
  if (!isAuthenticated) return <></>

  return (
    <Router>
      <Sidebar>
        <Suspense
          fallback={
            <Flex
              minHeight='40vh'
              justify='center'
              alignItems='center'
              height='100%'
              width='100%'
            >
              <VantageLoader />
            </Flex>
          }
        >
          <Switch>
            <PrivateRoute component={Home} path='/' exact />
            {/* ensures that the user needs an organization on its token */}
            {!hasOrganizationInToken && <Redirect from='*' to='/' push />}
            <AdminRoute component={Admin} path='/admin' />
            <PrivateRoute component={Cluster} path='/cluster' />
            <PrivateRoute component={Jobs} path='/jobs' />
            <PrivateRoute component={LicenseManager} path='/lm' />
            <PrivateRoute component={Storage} path='/storage' />
            <PrivateRoute component={Support} path='/support' />
            <PrivateRoute component={PrivacyPolicy} path='/privacy-policy' />
            <Route path='*' component={Page404} />
          </Switch>
        </Suspense>
      </Sidebar>
    </Router>
  )
}

export default PrivateRouter
