import { useCallback, useRef } from 'react'
import { useBoolean } from '@chakra-ui/react'
import { FieldInputProps, FormikProps, FormikValues } from 'formik'

type UseFormikFieldUniqueDebouncedProps<FormValues> = {
  queryFn: (value: string) => Promise<boolean>
  virtualFieldName: keyof FormValues & string
}

const useFormikFieldUniqueDebounced = <FormValues extends FormikValues>({
  queryFn,
  virtualFieldName,
}: UseFormikFieldUniqueDebouncedProps<FormValues>) => {
  const debounceRef = useRef<NodeJS.Timeout>()
  const [isCheckingUniqueValue, setIsCheckingUniqueValue] = useBoolean()

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      field: FieldInputProps<string>,
      form: FormikProps<FormValues>
    ) => {
      const { value } = event.target
      form.setFieldValue(field.name, value)
      if (debounceRef.current) clearTimeout(debounceRef.current)
      if (!value) {
        setIsCheckingUniqueValue.off()
        return
      }
      setIsCheckingUniqueValue.on()
      // resets virtualField to true
      form.setFieldValue(virtualFieldName, true)
      // check for unique value
      debounceRef.current = setTimeout(async () => {
        const isValueUnique = await queryFn(value)
        form.setFieldValue(virtualFieldName, isValueUnique)
        setIsCheckingUniqueValue.off()
      }, 300)
    },
    [setIsCheckingUniqueValue, queryFn, virtualFieldName]
  )

  return {
    handleInputChange,
    isCheckingUniqueValue,
  }
}

export default useFormikFieldUniqueDebounced
