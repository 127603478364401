const Avatar = {
  parts: ['label', 'badge', 'container', 'excessLabel', 'group'],
  baseStyle: {
    label: {
      color: 'white',
      fontWeight: 'semibold',
    },
  },
}

export default Avatar
