import { useState } from 'react'
import {
  Box,
  chakra,
  Flex,
  GridItem,
  HStack,
  Icon,
  MenuItem,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { BiHeadphone } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import AvatarWithInitials from '@/components/atoms/AvatarWithInitials'

import { clearAlert } from './services'
import { AlertResponse } from '@/commons/interfaces/api/notifications'

type NotificationMenuItemProps = {
  alert: AlertResponse
  refetch: () => void
}

const NotificationMenuItem = ({
  alert,
  refetch,
}: NotificationMenuItemProps) => {
  const history = useHistory()
  const ticketId = alert.actions.path.split('.').slice(-1)
  const [isClearing, setIsClearing] = useState(false)

  const handleClearAlert = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (!alert.id || isClearing) return
    setIsClearing(true)
    clearAlert(alert.id).finally(() => {
      refetch()
      setIsClearing(false)
    })
  }

  const handleClickItem = () => {
    history.push(`/support/${ticketId}`)
    clearAlert(alert.id!).finally(refetch)
  }

  return (
    <MenuItem
      _focus={{
        backgroundColor: alert.checked ? 'white' : 'fluorescentBlue.50',
      }}
      _hover={{
        backgroundColor: '#F4FBFF',
      }}
      backgroundColor={alert.checked ? 'white' : 'fluorescentBlue.50'}
      onClick={handleClickItem}
      paddingLeft={3}
      paddingRight={2}
      paddingY={2}
      transition='background-color 0.5s'
    >
      <SimpleGrid columns={28} width='100%'>
        <AvatarWithInitials
          name={alert.actions.userName || alert.actions.userEmail}
        />
        <GridItem colStart={6} colEnd={27}>
          <VStack
            height='48px'
            justifyContent='center'
            spacing={0}
            alignItems='flex-start'
          >
            <Text color='black'>
              <chakra.b>{alert.actions.userEmail}</chakra.b> commented in Ticket{' '}
              {ticketId}
            </Text>
            <HStack
              color='gray.600'
              justify='space-between'
              width='100%'
              fontSize='xs'
            >
              <HStack spacing={1}>
                <Icon as={BiHeadphone} boxSize={3} color='gray.600' />
                <chakra.span>Support</chakra.span>
              </HStack>
              <chakra.span>
                {DateTime.fromISO(alert.actions.createdAt ?? '').toRelative()}
              </chakra.span>
            </HStack>
          </VStack>
        </GridItem>
        {!alert.checked && (
          <GridItem colSpan={2}>
            <Flex justify='flex-end'>
              <Tooltip
                label='Click to clear this notification'
                hasArrow
                placement='top-start'
                offset={[6, 10]}
              >
                <Box
                  _hover={{
                    backgroundColor: 'fluorescentBlue.600',
                  }}
                  width='8px'
                  height='8px'
                  borderRadius='full'
                  backgroundColor='fluorescentBlue.500'
                  marginRight='4px'
                  transition='opacity 0.2s'
                  onClick={handleClearAlert}
                />
              </Tooltip>
            </Flex>
          </GridItem>
        )}
      </SimpleGrid>
    </MenuItem>
  )
}

export default NotificationMenuItem
