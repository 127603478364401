import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts'
import {
  keycloakClient,
  keycloakRedirectUrl,
  keycloakUrl,
} from './LocalEnvData'

const makeRedirectUri = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('register') === 'true'
    ? keycloakRedirectUrl + '?register=true'
    : keycloakRedirectUrl
}

const userConfig: UserManagerSettings = {
  authority: keycloakUrl,
  client_id: keycloakClient,
  redirect_uri: makeRedirectUri(),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const UserManagerClient = new UserManager(userConfig)

export default UserManagerClient
