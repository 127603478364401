const ClusterEmptyStateIcon = () => (
  <svg
    width='86'
    height='86'
    viewBox='0 0 86 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M43 85.1875C66.2995 85.1875 85.1875 66.2995 85.1875 43C85.1875 19.7005 66.2995 0.8125 43 0.8125C19.7005 0.8125 0.8125 19.7005 0.8125 43C0.8125 66.2995 19.7005 85.1875 43 85.1875Z'
      fill='#EEECFB'
    />
    <mask
      id='mask0_2447_7101'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='86'
      height='86'
    >
      <path
        d='M43 85.1875C66.2995 85.1875 85.1875 66.2995 85.1875 43C85.1875 19.7005 66.2995 0.8125 43 0.8125C19.7005 0.8125 0.8125 19.7005 0.8125 43C0.8125 66.2995 19.7005 85.1875 43 85.1875Z'
        fill='#EEECFB'
      />
    </mask>
    <g mask='url(#mask0_2447_7101)'>
      <path
        d='M67.1875 17.125H18.8125C17.2592 17.125 16 18.5375 16 20.2799V86.5326C16 88.275 17.2592 89.6875 18.8125 89.6875H67.1875C68.7408 89.6875 70 88.275 70 86.5326V20.2799C70 18.5375 68.7408 17.125 67.1875 17.125Z'
        fill='white'
      />
    </g>
    <path
      d='M55.375 59.3125H30.625C29.693 59.3125 28.9375 60.068 28.9375 61C28.9375 61.932 29.693 62.6875 30.625 62.6875H55.375C56.307 62.6875 57.0625 61.932 57.0625 61C57.0625 60.068 56.307 59.3125 55.375 59.3125Z'
      fill='#CDC6F1'
    />
    <path
      d='M55.375 66.625H30.625C29.693 66.625 28.9375 67.3805 28.9375 68.3125C28.9375 69.2445 29.693 70 30.625 70H55.375C56.307 70 57.0625 69.2445 57.0625 68.3125C57.0625 67.3805 56.307 66.625 55.375 66.625Z'
      fill='#CDC6F1'
    />
    <path
      d='M33.625 45.9531V50.6406H52.3773L52.375 45.9531H33.625ZM47.6875 49.4687H45.3438V47.125H47.6875V49.4687ZM51.2031 49.4687H48.8594V47.125H51.2031V49.4687ZM33.625 34.2344V38.9219H52.3773L52.375 34.2344H33.625ZM47.6875 37.75H45.3438V35.4062H47.6875V37.75ZM51.2031 37.75H48.8594V35.4062H51.2031V37.75Z'
      fill='white'
    />
    <path
      d='M52.375 31.8906H33.625C32.3324 31.8906 31.2812 32.9418 31.2812 34.2344V38.9219C31.2812 40.2145 32.3324 41.2656 33.625 41.2656H52.375C53.6676 41.2656 54.7187 40.2145 54.7187 38.9219V34.2344C54.7187 32.9418 53.6676 31.8906 52.375 31.8906ZM33.625 38.9219V34.2344H52.375L52.3773 38.9219H33.625ZM52.375 43.6094H33.625C32.3324 43.6094 31.2812 44.6605 31.2812 45.9531V50.6406C31.2812 51.9332 32.3324 52.9844 33.625 52.9844H52.375C53.6676 52.9844 54.7187 51.9332 54.7187 50.6406V45.9531C54.7187 44.6605 53.6676 43.6094 52.375 43.6094ZM33.625 50.6406V45.9531H52.375L52.3773 50.6406H33.625Z'
      fill='#ABA0E9'
    />
    <path
      d='M48.8594 35.4062H51.2031V37.75H48.8594V35.4062ZM45.3438 35.4062H47.6875V37.75H45.3438V35.4062ZM48.8594 47.125H51.2031V49.4688H48.8594V47.125ZM45.3438 47.125H47.6875V49.4688H45.3438V47.125Z'
      fill='#ABA0E9'
    />
    <g clipPath='url(#clip0_2447_7101)'>
      <path
        d='M58.7351 23.6441C58.6988 23.5378 58.6029 23.4627 58.4909 23.454L56.8875 23.3266L56.1937 21.7907C56.1484 21.6897 56.048 21.625 55.9375 21.625C55.8269 21.625 55.7265 21.6897 55.6813 21.7904L54.9874 23.3266L53.384 23.454C53.274 23.4627 53.1793 23.535 53.1419 23.6388C53.1044 23.7425 53.1309 23.8587 53.2099 23.9358L54.3948 25.0908L53.9758 26.9055C53.9499 27.0177 53.9952 27.1344 54.0899 27.1999C54.1383 27.2331 54.194 27.25 54.25 27.25C54.3043 27.25 54.3588 27.2343 54.4061 27.2028L55.9375 26.1818L57.4689 27.2028C57.5668 27.268 57.6953 27.2655 57.7909 27.196C57.886 27.1265 57.9279 27.0047 57.8955 26.8917L57.3811 25.0917L58.6569 23.9436C58.7404 23.8683 58.7711 23.7507 58.7351 23.6441Z'
        fill='#7868DD'
      />
    </g>
    <g opacity='0.5' clipPath='url(#clip1_2447_7101)'>
      <path
        d='M27.8274 23.6441C27.8637 23.5378 27.9596 23.4627 28.0716 23.454L29.675 23.3266L30.3688 21.7907C30.4141 21.6897 30.5145 21.625 30.625 21.625C30.7356 21.625 30.836 21.6897 30.8812 21.7904L31.5751 23.3266L33.1785 23.454C33.2885 23.4627 33.3832 23.535 33.4206 23.6388C33.4581 23.7425 33.4316 23.8587 33.3526 23.9358L32.1677 25.0908L32.5867 26.9055C32.6126 27.0177 32.5673 27.1344 32.4726 27.1999C32.4242 27.2331 32.3685 27.25 32.3125 27.25C32.2582 27.25 32.2037 27.2343 32.1564 27.2028L30.625 26.1818L29.0936 27.2028C28.9957 27.268 28.8672 27.2655 28.7716 27.196C28.6765 27.1265 28.6346 27.0047 28.667 26.8917L29.1814 25.0917L27.9056 23.9436C27.8221 23.8683 27.7914 23.7507 27.8274 23.6441Z'
        fill='#7868DD'
      />
    </g>
    <g opacity='0.5'>
      <path
        d='M64.9226 30.3941C64.8863 30.2878 64.7904 30.2127 64.6784 30.204L63.075 30.0766L62.3812 28.5407C62.3359 28.4397 62.2355 28.375 62.125 28.375C62.0144 28.375 61.914 28.4397 61.8688 28.5404L61.1749 30.0766L59.5715 30.204C59.4615 30.2127 59.3668 30.285 59.3294 30.3888C59.2919 30.4925 59.3184 30.6087 59.3974 30.6858L60.5823 31.8408L60.1633 33.6555C60.1374 33.7677 60.1827 33.8844 60.2774 33.9499C60.3258 33.9831 60.3815 34 60.4375 34C60.4918 34 60.5463 33.9843 60.5936 33.9528L62.125 32.9318L63.6564 33.9528C63.7543 34.018 63.8828 34.0155 63.9784 33.946C64.0735 33.8765 64.1154 33.7547 64.083 33.6417L63.5686 31.8417L64.8444 30.6936C64.9279 30.6183 64.9586 30.5007 64.9226 30.3941Z'
        fill='#7868DD'
      />
    </g>
    <g clipPath='url(#clip2_2447_7101)'>
      <path
        d='M21.6399 30.3941C21.6762 30.2878 21.7721 30.2127 21.8841 30.204L23.4875 30.0766L24.1813 28.5407C24.2266 28.4397 24.327 28.375 24.4375 28.375C24.5481 28.375 24.6485 28.4397 24.6937 28.5404L25.3876 30.0766L26.991 30.204C27.101 30.2127 27.1957 30.285 27.2331 30.3888C27.2706 30.4925 27.2441 30.6087 27.1651 30.6858L25.9802 31.8408L26.3992 33.6555C26.4251 33.7677 26.3798 33.8844 26.2851 33.9499C26.2367 33.9831 26.181 34 26.125 34C26.0707 34 26.0162 33.9843 25.9689 33.9528L24.4375 32.9318L22.9061 33.9528C22.8082 34.018 22.6797 34.0155 22.5841 33.946C22.489 33.8765 22.4471 33.7547 22.4795 33.6417L22.9939 31.8417L21.7181 30.6936C21.6346 30.6183 21.6039 30.5007 21.6399 30.3941Z'
        fill='#7868DD'
      />
    </g>
    <defs>
      <clipPath id='clip0_2447_7101'>
        <rect
          width='6.75'
          height='6.75'
          fill='white'
          transform='translate(52.5625 21.0625)'
        />
      </clipPath>
      <clipPath id='clip1_2447_7101'>
        <rect
          width='6.75'
          height='6.75'
          fill='white'
          transform='matrix(-1 0 0 1 34 21.0625)'
        />
      </clipPath>
      <clipPath id='clip2_2447_7101'>
        <rect
          width='6.75'
          height='6.75'
          fill='white'
          transform='matrix(-1 0 0 1 27.8125 27.8125)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default ClusterEmptyStateIcon
