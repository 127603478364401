import React, { ChangeEvent, ReactNode, useMemo, useRef } from 'react'
import { get } from 'lodash'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Spinner,
} from '@chakra-ui/react'
import {
  Field,
  FieldInputProps as FormikFieldInputProps,
  FieldProps as FormikFieldProps,
  FormikProps,
} from 'formik'

import { FieldBaseProps } from '@/commons/types'
import FieldLabel from '@/components/atoms/FieldLabel'

import InnerInput from './components/InnerInput'
import InnerTextarea from './components/InnerTextarea'

import type { InputProps } from '@chakra-ui/react'

export interface FieldInputProps extends FieldBaseProps {
  fieldHelpText?: string | ReactNode
  focusOnMount?: boolean
  isTextarea?: boolean
  isLoading?: boolean
  isValid?: boolean
  LeftElementComponent?: React.ReactNode
  maxChars?: number
  onChange?: (
    event: ChangeEvent<any>,
    field: FormikFieldInputProps<any>,
    form: FormikProps<any>
  ) => void
  RightElementComponent?: React.ReactNode
  type?: string
}

const FieldInput = ({
  externalError,
  fieldHelpText,
  focusOnMount,
  hideErrorMessage,
  isDisabled,
  isLoading,
  isRequired,
  isTextarea,
  isValid,
  label,
  LabelComponent,
  labelProps,
  LeftElementComponent,
  maxChars,
  name,
  onChange,
  placeholder,
  RightElementComponent,
  subLabel,
  tooltipMessage,
  type,
  ...props
}: FieldInputProps & Omit<InputProps, 'onChange'>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const RightElement = useMemo(() => {
    if (RightElementComponent) return RightElementComponent
    return isLoading ? <Spinner color='black' size='sm' /> : null
  }, [isLoading, RightElementComponent])

  return (
    <Field name={name}>
      {({ field, form }: FormikFieldProps) => {
        const errorMessage = get(form, `errors.${name}`) || externalError,
          hasError = !!(errorMessage && get(form, `touched.${name}`))

        const InputComponent = (
          isTextarea ? InnerTextarea : InnerInput
        ) as React.ElementType

        const handleChange = (
          event:
            | ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
        ) => {
          if (onChange) {
            onChange(event, field, form)
            return
          }
          event.target.value = event.target.value.slice(0, maxChars)
          field.onChange(event)
        }

        return (
          <FormControl isRequired={isRequired} isInvalid={hasError} {...props}>
            {label && (
              <FieldLabel
                hasError={hasError}
                label={label}
                LabelComponent={LabelComponent}
                labelProps={labelProps}
                name={name}
                subLabel={subLabel}
                tooltipMessage={tooltipMessage}
              />
            )}

            <InputComponent
              {...props}
              field={field}
              focusOnMount={focusOnMount}
              hasError={hasError}
              innerRef={inputRef}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isValid={isValid}
              LeftElementComponent={LeftElementComponent}
              name={name}
              onChange={handleChange}
              placeholder={placeholder}
              RightElementComponent={RightElement}
              type={type}
            />

            {maxChars && (
              <FormHelperText marginTop={1} fontSize='xs'>
                {maxChars - field.value.length} characters left.
              </FormHelperText>
            )}

            {fieldHelpText && (
              <FormHelperText marginTop={1} fontSize='xs'>
                {fieldHelpText}
              </FormHelperText>
            )}

            {!hideErrorMessage && (
              <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
                {errorMessage}
              </FormErrorMessage>
            )}
          </FormControl>
        )
      }}
    </Field>
  )
}

export default React.memo(
  FieldInput,
  (prevProps, nextProps) =>
    prevProps.externalError === nextProps.externalError &&
    prevProps.isDisabled === nextProps.isDisabled &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isValid === nextProps.isValid &&
    prevProps.name === nextProps.name &&
    prevProps.RightElementComponent === nextProps.RightElementComponent
)
