const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      color: 'black',
    },
  },
  sizes: {
    md: {
      field: {
        _placeholder: {
          fontSize: 'sm',
        },
        fontSize: 'sm',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
  variants: {
    outline: {
      field: {
        _focus: {
          borderColor: 'iris.500',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'red.500',
          color: 'red.600',
          boxShadow: 'none',
          _placeholder: {
            color: 'red.500',
          },
        },
        _disabled: {
          backgroundColor: 'gray.100',
          opacity: 0.5,
          cursor: 'not-allowed',
          color: 'gray.600',
          borderColor: 'gray.300',
        },
        // Applying a box shadow is the only way to change the autofill background color
        _autofill: {
          // iris.50
          boxShadow: '0 0 0 1000px #EEECFB inset',
          backgroundColor: 'transparent !important',
        },
        _placeholder: {
          fontSize: 'sm',
          color: 'blackAlpha.500',
        },
        borderColor: 'gray.200',
        borderRadius: '8px',
        borderWidth: '1px',
        color: 'gray.700',
      },
    },
  },
}

export default Input
