import { components, DropdownIndicatorProps, GroupBase } from 'react-select'
import { Icon } from '@chakra-ui/react'
import { BiChevronDown } from 'react-icons/bi'

const DropdownIndicator = ({
  children,
  ...props
}: DropdownIndicatorProps<any, boolean, GroupBase<any>>) => (
  <components.DropdownIndicator {...props}>
    <Icon
      cursor='pointer'
      as={BiChevronDown}
      boxSize='22.5px'
      color='iris.500'
    />
  </components.DropdownIndicator>
)

export default DropdownIndicator
