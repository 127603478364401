import { AxiosError } from 'axios'
import { useRef } from 'react'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  Link,
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { BiSolidDownload } from 'react-icons/bi'

import { Axios } from '@/commons/helpers'
import { useToasts } from '@/hooks'

type JobbergateDownloadFileButtonProps = {
  boxColor?: string
  boxSize?: string
  downloadFileEndpoint: string
  filename: string
  spinnerBoxSize?: string
}

const downloadJobbergateFile = async (downloadFileEndpoint: string) => {
  try {
    const blobResponse = await Axios.get<Blob>(downloadFileEndpoint, {
      responseType: 'blob',
    })
    return blobResponse.data
  } catch (error) {
    console.error(error)
  }
}

const JobbergateDownloadFileButton = ({
  boxColor = 'iris.500',
  boxSize = '16px',
  downloadFileEndpoint,
  filename,
  spinnerBoxSize = '12px',
}: JobbergateDownloadFileButtonProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const { renderErrorToast } = useToasts({
    errorDescription: 'Something went wrong while downloading the file.',
  })
  const { isFetching, refetch } = useQuery<
    Blob | undefined,
    AxiosError['response']
  >(
    ['downloadFile', filename],
    () => downloadJobbergateFile(downloadFileEndpoint),
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data || !linkRef.current) return
        linkRef.current.href = window.URL.createObjectURL(data)
        linkRef.current.click()
      },
      onError: (error) => {
        console.error(error)
        renderErrorToast(error?.status)
      },
    }
  )

  return (
    <>
      <Link ref={linkRef} download={filename} display='none' />
      {isFetching && (
        <Flex>
          <Spinner color={boxColor} boxSize={spinnerBoxSize} />
        </Flex>
      )}
      {!isFetching && (
        <Tooltip label='Download File' hasArrow placement='top-start'>
          <Box>
            <IconButton
              _active={{
                backgroundColor: 'transparent',
              }}
              _disabled={{
                opacity: 0.5,
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label='download file'
              height='fit-content'
              icon={
                <Icon as={BiSolidDownload} color={boxColor} boxSize={boxSize} />
              }
              minWidth='fit-content'
              onClick={() => refetch()}
              variant='ghost'
            />
          </Box>
        </Tooltip>
      )}
    </>
  )
}

export default JobbergateDownloadFileButton
