const Tooltip = {
  baseStyle: {
    backgroundColor: 'russianViolet.600',
    borderRadius: '4px',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'normal',
    // russianViolet.600
    '--popper-arrow-bg': '#0E0831',
  },
}
export default Tooltip
