import { ChangeEvent, useEffect } from 'react'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
} from '@chakra-ui/react'
import { FieldInputProps as FormikFieldInputProps, FormikProps } from 'formik'
import { FieldBaseProps } from '@/commons/types'

export interface InnerInputProps extends FieldBaseProps {
  field: FormikFieldInputProps<any>
  focusOnMount?: boolean
  form: FormikProps<any>
  hasError: boolean
  innerRef: React.RefObject<HTMLInputElement>
  isValid?: boolean
  LeftElementComponent?: React.ReactNode
  name: string
  onChange: (event: ChangeEvent<any>) => void
  placeholder?: string
  RightElementComponent?: React.ReactNode
  type: string
}

const InnerInput = ({
  field,
  focusOnMount,
  hasError,
  innerRef,
  isDisabled,
  isRequired,
  isValid,
  LeftElementComponent,
  name,
  onChange,
  placeholder,
  RightElementComponent,
  type,
  ...props
}: InnerInputProps & InputProps) => {
  useEffect(() => {
    if (focusOnMount) {
      innerRef?.current?.focus()
    }
  }, [innerRef, focusOnMount])
  return (
    <InputGroup>
      {!!LeftElementComponent && (
        <InputLeftElement children={LeftElementComponent} />
      )}
      <Input
        {...field}
        id={name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        placeholder={placeholder}
        ref={innerRef}
        type={type}
        color={isValid ? 'green.600' : 'gray.700'}
        borderColor={isValid ? 'green.500' : 'gray.200'}
        {...props}
      />
      {!!RightElementComponent && (
        <InputRightElement children={RightElementComponent} />
      )}
    </InputGroup>
  )
}

export default InnerInput
