import { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from '@/hooks'
import { selectSidebarIsOpen } from '@/store/sidebarSlice'

import Footer from '@/components/atoms/Footer'
import Header from '@/components/organisms/Header'
import SidebarContent from '@/components/organisms/SidebarContent'

import BgDashboard from '@/assets/images/BgDashboard.png'

const Sidebar = ({ children }: { children: ReactNode }) => {
  const isSidebarOpen = useAppSelector(selectSidebarIsOpen)
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  return (
    <Box
      backgroundColor={isHomePage ? '#32297f' : 'unset'}
      backgroundPosition='right bottom'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      bgImage={isHomePage ? BgDashboard : 'none'}
      minHeight='100vh'
      minWidth={isSidebarOpen ? '1000px' : '770px'}
      position='relative'
    >
      <SidebarContent />
      <Flex minHeight='100vh' flexDirection='column'>
        <Header isHomePage={isHomePage} />
        <Box
          flexGrow={1000}
          marginLeft={isSidebarOpen ? '280px' : '98px'}
          paddingRight={5}
          transition='margin-left 0.25s ease'
        >
          {children}
        </Box>
        <Box
          flexGrow={1}
          flexShrink={0}
          paddingLeft={isSidebarOpen ? '280px' : '98px'}
          paddingRight={5}
          paddingY={4}
          transition='padding-left 0.25s ease'
          width='100%'
        >
          <Footer isHomePage={isHomePage} />
        </Box>
      </Flex>
    </Box>
  )
}

export default Sidebar
