import { useEffect } from 'react'
import { logout, selectIsInvalidToken } from '@/store/guardianSlice'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useKeycloak from './useKeycloak'

const useAppAuth = () => {
  const dispatch = useAppDispatch()
  const isInvalidToken = useAppSelector(selectIsInvalidToken)
  const {
    error,
    initialized,
    isAuthenticated,
    parsedRefreshToken,
    signinRedirect,
    signoutRedirect,
    startSilentRenew,
  } = useKeycloak()

  // logout if the refresh token is expired
  useEffect(() => {
    if (
      parsedRefreshToken &&
      parsedRefreshToken.exp &&
      parsedRefreshToken.exp < Date.now() / 1000
    ) {
      dispatch(logout())
    }
  }, [dispatch, parsedRefreshToken])

  // logout if there is an error
  useEffect(() => {
    if (error) {
      dispatch(logout())
    }
  }, [error, dispatch])

  // auto signin
  useEffect(() => {
    if (initialized && !isAuthenticated) {
      signinRedirect()
    }
  }, [initialized, signinRedirect, isAuthenticated])

  // logout effect: all logouts must follow this flow
  useEffect(() => {
    if (isInvalidToken) {
      signoutRedirect({ post_logout_redirect_uri: window.location.origin })
    }
  }, [isInvalidToken, signoutRedirect])

  // auto silent renew
  useEffect(() => {
    if (initialized && isAuthenticated) {
      startSilentRenew()
    }
  }, [initialized, isAuthenticated, startSilentRenew])
}

export default useAppAuth
