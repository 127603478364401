import {
  chakra,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

import ActionButtons from '@/components/atoms/ActionButtons'

type CodeEditorDrawerDeleteFileModalProps = {
  filename: string
  isOpen: boolean
  isSubmitting: boolean
  onClose: () => void
  onDeleteFile: () => void
}

const CodeEditorDrawerDeleteFileModal = ({
  filename,
  isOpen,
  isSubmitting,
  onClose,
  onDeleteFile,
}: CodeEditorDrawerDeleteFileModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='512px'>
        <ModalHeader paddingBottom={4}>
          <HStack justifyContent='space-between'>
            <chakra.span>Delete File</chakra.span>
            <IconButton
              aria-label='close modal'
              icon={<Icon as={BiX} boxSize='18px' />}
              onClick={onClose}
              variant='modal-close-button'
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Text>
            What do you want to do{' '}
            <chakra.span fontWeight='semibold'>delete</chakra.span> the
            following file?
          </Text>
          <Text fontWeight='bold' marginTop={4}>
            {filename}
          </Text>
        </ModalBody>
        <ModalFooter paddingTop={0}>
          <ActionButtons
            isSubmitting={isSubmitting}
            onClickCancel={onClose}
            onClickSubmit={onDeleteFile}
            submitLabel='Delete'
            submitType='button'
            submitVariant='danger'
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CodeEditorDrawerDeleteFileModal
