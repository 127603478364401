import theme from '@/theme'
import hexToRgba from './hexToRgba'

const possibleShades = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
] as const

type PossibleShades = typeof possibleShades[number]

type ColorString = `${string}.${PossibleShades}` | string
type SplittedColorString = [string, PossibleShades]

type Colors = {
  [key: string]: string | { [key in PossibleShades]: string }
}

// the reason for this function is to get a color with opacity
// from the theme colors instead of using the rgba code
const getColorWithOpacity = (colorString: ColorString, opacity: number = 1) => {
  const colors = theme.colors as Colors
  // if colorShade is undefined, it will be '50' by default if the color has shades
  const [colorName, colorShade = '50'] = colorString.split(
    '.'
  ) as SplittedColorString
  if (colorShade && !possibleShades.includes(colorShade)) {
    throw new Error(`Color shade ${colorShade} is not valid`)
  }

  try {
    const colorHex =
      colors[colorName] === 'string' && !colorShade
        ? colors[colorName]
        : colors[colorName][colorShade]

    return hexToRgba(colorHex as string, opacity)
  } catch (error) {
    console.log(error)
  }
}

export default getColorWithOpacity
