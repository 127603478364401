import { HStack, Text } from '@chakra-ui/react'

type PageHeadingProps = {
  title: string
  caption?: string
  CallToActionComponent?: React.ReactNode
}

const PageHeading = ({
  title,
  caption,
  CallToActionComponent,
}: PageHeadingProps) => (
  <HStack justifyContent='space-between' spacing={0} width='100%'>
    <HStack spacing={2}>
      <Text
        lineHeight={10}
        color='gray.700'
        fontWeight='semibold'
        fontSize='xl'
      >
        {title}
      </Text>
      <Text fontSize='sm' color='gray.500'>
        {caption}
      </Text>
    </HStack>
    {CallToActionComponent}
  </HStack>
)

export default PageHeading
