import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  chakra,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

interface DialogContainerProps {
  dialogContent: any
  onCloseDialog: () => void
  context?: string
}

const DialogContainer = ({
  dialogContent,
  onCloseDialog,
}: DialogContainerProps) => (
  <Modal isOpen={!!dialogContent} onClose={onCloseDialog} isCentered>
    <ModalOverlay />
    <ModalContent minW='fit-content'>
      <ModalHeader textTransform='capitalize'>
        <HStack justifyContent='space-between'>
          <chakra.span color='russianViolet.600'>
            {dialogContent?.title}
          </chakra.span>
          <IconButton
            aria-label='close modal'
            icon={<Icon as={BiX} boxSize='18px' />}
            onClick={onCloseDialog}
            variant='modal-close-button'
          />
        </HStack>
      </ModalHeader>
      <ModalBody>
        {dialogContent &&
          React.createElement(dialogContent.dialogBodyComponent, {
            content: dialogContent.content,
            handleCloseDialog: dialogContent.handleCloseDialog,
            refetch: dialogContent.refetch,
            context: dialogContent.context,
          })}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default DialogContainer
