import React, { useRef } from 'react'
import { Flex, HStack, useDimensions } from '@chakra-ui/react'

import {
  IRowComponentProps,
  RemoveLineButton,
} from '@/components/molecules/FieldMultiInputs'
import { MultiInputErrors } from '../InnerFieldMultiInputs'

interface FieldMultiInputsDefaultRowProps<InputType> {
  allowRemoveAll?: boolean
  index: number
  lineErrors: MultiInputErrors<InputType> | undefined
  original: InputType[]
  push: (value: InputType) => void
  remove: (index: number) => void
  RowComponent: React.FC<IRowComponentProps<InputType>>
}

const FieldMultiInputsDefaultRow = <InputType extends any>({
  allowRemoveAll,
  index,
  lineErrors,
  original,
  push,
  remove,
  RowComponent,
}: FieldMultiInputsDefaultRowProps<InputType>) => {
  const ref = useRef(null)
  const elementDimensions = useDimensions(ref)
  const height = elementDimensions?.borderBox.height

  return (
    <HStack
      key={index}
      width='100%'
      spacing={3}
      ref={ref}
      alignItems='flex-start'
    >
      <RowComponent
        fieldValues={original}
        index={index}
        key={index}
        lineErrors={lineErrors}
        push={push}
        remove={remove}
      />
      <Flex height={height ? `${height}px` : 'auto'} alignItems='center'>
        <RemoveLineButton
          onClick={() => remove(index)}
          isDisabled={allowRemoveAll ? false : original.length === 1}
        />
      </Flex>
    </HStack>
  )
}

export default FieldMultiInputsDefaultRow
