import { useEffect } from 'react'
import { Flex, Table, Thead, Tr, Th, Tbody, Box, Icon } from '@chakra-ui/react'
import {
  useTable,
  useSortBy,
  Column,
  TableOptions,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import TableCell from '@/components/atoms/TableCell'

type TableComponentProps<T extends Object = any> = {
  columns: Column<T>[]
  data: object[]
  error?: any
  globalFilterValue?: string
  hideTableHeader?: boolean
  isLoading?: boolean
  onClickRow?: (content: any, handleOpenDialog?: (content: any) => void) => void
  onCloseDialog: () => void
  onOpenDialog: (content: any) => void
  onSort?: (sortBy: Array<{ id: string; desc?: boolean }>) => void
  refetch?: () => void
  sortByInitialState?: Array<{ id: string; desc?: boolean }>
}

interface TableOptionsExtended extends TableOptions<object> {
  handleOpenDialog: (content: any) => void
  handleCloseDialog: () => void
  refetch: () => void
}

const TableComponent = ({
  columns,
  data,
  globalFilterValue,
  hideTableHeader,
  onClickRow,
  onCloseDialog,
  onOpenDialog,
  onSort,
  refetch,
  sortByInitialState,
}: TableComponentProps) => {
  const changeGlobalFilter = useAsyncDebounce(
    (globalFilterValue) => setGlobalFilter(globalFilterValue),
    300
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      handleOpenDialog: onOpenDialog,
      handleCloseDialog: onCloseDialog,
      refetch,
      manualSortBy: !!onSort,
      initialState: {
        sortBy: sortByInitialState ?? [],
      },
    } as TableOptionsExtended,
    useGlobalFilter,
    useSortBy
  )

  useEffect(() => {
    changeGlobalFilter(globalFilterValue)
  }, [globalFilterValue, changeGlobalFilter])

  useEffect(() => {
    if (onSort) onSort(sortBy)
  }, [onSort, sortBy])

  return (
    <Box height='100%' overflow='auto' width='100%'>
      <Table {...getTableProps()}>
        <Thead display={hideTableHeader ? 'none' : 'auto'}>
          {/* todo add better types */}
          {headerGroups.map((headerGroup: any) => (
            <Tr
              {...headerGroup.getHeaderGroupProps()}
              backgroundColor='gray.50'
              borderRadius='6px'
            >
              {/* todo add better types */}
              {headerGroup.headers.map((column: any) => {
                const columnProps = column.getHeaderProps(
                  column.getSortByToggleProps({
                    title: 'Sort By',
                  })
                )
                return (
                  <Th
                    {...columnProps}
                    isNumeric={column.isNumeric}
                    height='40px'
                    verticalAlign='center'
                    paddingY={0}
                    whiteSpace='nowrap'
                    paddingRight={4}
                    color='gray.700'
                  >
                    <Flex
                      alignItems='center'
                      justifyContent={
                        column.isActionColumn ? 'flex-end' : 'unset'
                      }
                    >
                      {column.render('Header')}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <Icon
                            as={BiDownArrowAlt}
                            color='iris.500'
                            boxSize={3.5}
                            marginLeft={2}
                          />
                        ) : (
                          <Icon
                            as={BiUpArrowAlt}
                            color='iris.500'
                            boxSize={3.5}
                            marginLeft={2}
                          />
                        ))}
                    </Flex>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr
                {...row.getRowProps()}
                _hover={{ backgroundColor: 'gray.50' }}
                cursor={onClickRow ? 'pointer' : 'default'}
                onClick={() => {
                  onClickRow && onClickRow(row, onOpenDialog)
                }}
              >
                {/* todo add better types */}
                {row.cells.map((cell: any, index) => (
                  <TableCell cell={cell} index={index} key={index} />
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

export default TableComponent
