import React from 'react'
import { chakra, Icon, Link, HStack } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom'
import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'

type RootItemProps = {
  isSidebarOpen: boolean
  name: string
  SectionIcon: IconType
} & (
  | {
      route: string
      externalUrl?: undefined
    }
  | {
      externalUrl: string
      route?: undefined
    }
)

const RootItem = ({
  externalUrl,
  isSidebarOpen,
  location,
  name,
  route,
  SectionIcon,
}: RootItemProps & RouteComponentProps) => {
  const section = location.pathname.slice(1).split('/')[0]
  const isActive = section === route?.slice(1)

  return (
    <Link
      _focus={{ outline: 'none', boxShadow: 'none' }}
      as={route ? RouterLink : Link}
      isExternal={!!externalUrl}
      marginTop={1}
      style={{ textDecoration: 'none' }}
      to={route}
      href={externalUrl}
    >
      <HStack
        spacing={3}
        align='center'
        cursor='pointer'
        fontWeight={isActive ? '600' : '400'}
        color={isActive ? 'fluorescentBlue.500' : 'iris.200'}
        fontSize='sm'
        paddingInlineStart={3}
        paddingInlineEnd='1rem'
        paddingY='0.5rem'
        _hover={{
          backgroundColor: getColorWithOpacity('iris.800', 0.5),
          borderRadius: '8px',
        }}
        height='37px'
        minWidth='224px'
      >
        <Icon
          as={SectionIcon}
          color={isActive ? 'navigationAccordionIcon' : 'fluorescentBlue.500'}
          boxSize={'18px'}
        />
        <chakra.span
          transition='opacity 0.150s ease'
          opacity={isSidebarOpen ? 1 : 0}
          fontSize='sm'
          fontWeight={isActive ? 'semibold' : 'normal'}
          color={'white'}
          lineHeight={5}
        >
          {name}
        </chakra.span>
      </HStack>
    </Link>
  )
}

export default RootItem
