import React, { useRef, useState } from 'react'
import { FormControl, FormErrorMessage, HStack } from '@chakra-ui/react'
import { uniqBy } from 'lodash'
import { useFormikContext, useField, FormikValues } from 'formik'
import { useQuery } from 'react-query'
import Select, { SingleValue } from 'react-select'

import { FieldBaseProps } from '@/commons/types'
import { useDebouncedInput } from '@/hooks'

import OptionComponent from './components/Option'
import ValueContainerComponent from './components/ValueContainer'
import PlaceholderComponent from './components/Placeholder'
import ClearIndicator from './components/ClearIndicator'

import makeStyles from './makeStyles'
import asyncService from './asyncService'
import { FieldLabel } from '@/components/atoms'

export type Option = {
  label: string
  value: any
  [key: string]: any
}

type ServiceParameters = {
  inputValue: string
  values: FormikValues
  signal: AbortSignal | undefined
}

interface FieldAsyncSelectProps extends FieldBaseProps {
  service: ({
    inputValue,
    values,
    signal,
  }: ServiceParameters) => Promise<Option[]>
  shouldDisable?: (values: any) => boolean
  placeholder?: string
  LeftAddon?: React.ReactNode
}

const FieldAsyncSelect = ({
  hideErrorMessage,
  isDisabled,
  isRequired,
  label,
  LabelComponent,
  labelProps,
  LeftAddon,
  name,
  placeholder,
  service,
  shouldDisable,
  subLabel,
  tooltipMessage,
}: FieldAsyncSelectProps) => {
  const [field, meta, fieldHelpers] = useField(name)
  const { values } = useFormikContext<{ [key: string]: any }>()

  const currentOption = useRef<SingleValue<Option | undefined>>()

  /* async controllers */
  const { inputValue, onDebouncedInputChange } = useDebouncedInput(500)
  const disabled = shouldDisable ? shouldDisable(values) : isDisabled
  const disabledPlaceholder = disabled ? placeholder : undefined

  /* select controllers */
  const [isFocused, setIsFocused] = useState(false)

  const { data, isFetching } = useQuery(
    [name, inputValue],
    ({ signal }) =>
      asyncService({
        inputValue,
        service,
        signal,
        values,
      }),
    {
      enabled: !disabled,
    }
  )

  const errorMessage = meta.error
  const hasError = !!(errorMessage && meta.touched)

  const fieldValue = (data ?? []).find(
    (option) => String(option.value) === String(field.value)
  )

  const options =
    data && currentOption.current
      ? // while searching the options list should only display the fetched list
        inputValue
        ? data
        : uniqBy(data.concat(currentOption.current as Option), 'value')
      : data

  /* handlers */
  const handleChangeOption = (newValue: SingleValue<Option | undefined>) => {
    fieldHelpers.setValue(newValue?.value ?? '')
    currentOption.current = newValue
  }

  const handleBlur = (): void => {
    fieldHelpers.setTouched(true)
    setIsFocused(false)
  }
  const handleFocus = () => setIsFocused(true)

  return (
    <FormControl isRequired={isRequired} isInvalid={hasError}>
      {label && (
        <FieldLabel
          hasError={hasError}
          label={label}
          LabelComponent={LabelComponent}
          labelProps={labelProps}
          name={name}
          subLabel={subLabel}
          tooltipMessage={tooltipMessage}
        />
      )}

      <HStack
        _hover={{
          borderColor: isFocused || isDisabled ? 'none' : 'gray.300',
        }}
        borderColor={
          isFocused
            ? 'iris.500'
            : !isDisabled && hasError
            ? 'red.500'
            : 'gray.200'
        }
        borderWidth='1px'
        borderRadius='8px'
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        spacing={0}
        width='100%'
      >
        {LeftAddon}
        <Select
          isClearable
          components={{
            Option: (props) => (
              <OptionComponent {...props} inputValue={inputValue} />
            ),
            ValueContainer: ValueContainerComponent,
            Placeholder: PlaceholderComponent,
            ClearIndicator: ClearIndicator,
          }}
          isLoading={isFetching}
          menuPortalTarget={document.body}
          name={name}
          onBlur={handleBlur}
          onChange={handleChangeOption}
          onFocus={handleFocus}
          onInputChange={onDebouncedInputChange}
          options={options}
          placeholder={disabledPlaceholder}
          styles={makeStyles}
          value={fieldValue}
        />
      </HStack>

      {!hideErrorMessage && (
        <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default FieldAsyncSelect
