import { components, ClearIndicatorProps, GroupBase } from 'react-select'
import { Icon } from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

const ClearIndicator = ({
  children,
  ...props
}: ClearIndicatorProps<any, boolean, GroupBase<any>>) => (
  <components.ClearIndicator {...props}>
    <Icon
      _hover={{ color: 'gray.600' }}
      cursor='pointer'
      as={BiX}
      boxSize='16px'
      color='gray.400'
    />
  </components.ClearIndicator>
)

export default ClearIndicator
