import { Box, chakra } from '@chakra-ui/react'

const findQueryOccurrencesInString = (
  positions: number[],
  str: string,
  query: string
) => {
  const upperCaseString = str.toUpperCase()
  const upperCaseQuery = query.toUpperCase()
  const queryLength = upperCaseQuery.length
  const queryStartPositionInStr = upperCaseString.indexOf(upperCaseQuery)
  if (queryStartPositionInStr === -1) return

  if (positions.length > 0) {
    const lastPosition = positions.slice(-1)[0]
    positions.push(queryStartPositionInStr + lastPosition + queryLength)
  } else {
    positions.push(queryStartPositionInStr)
  }
  const nextOccurrenceString = str.slice(queryStartPositionInStr + queryLength)
  findQueryOccurrencesInString(positions, nextOccurrenceString, query)
}

type BoldQueryProps = {
  str: string
  query: string
}

const BoldQuery = ({ str, query }: BoldQueryProps) => {
  const queryLength = query.length
  let positions: number[] = []
  findQueryOccurrencesInString(positions, str, query)

  return (
    <Box fontWeight='normal'>
      {positions.length > 0 &&
        positions.map((position: number, index: number, original) => (
          <chakra.span key={index}>
            {str.slice(index === 0 ? 0 : original[index - 1], position)}
            <chakra.b>{str.slice(position, position + queryLength)}</chakra.b>
            {index === original.length - 1
              ? str.slice(position + queryLength)
              : ''}
          </chakra.span>
        ))}
      {positions.length === 0 && str}
    </Box>
  )
}

export default BoldQuery
