import { Badge, BadgeProps, HStack, Text, TextProps } from '@chakra-ui/react'

type DataBadgeFieldProps = {
  colorScheme?: string
  label?: string
  labelTextProps?: TextProps
  value?: string
  valueBadgeProps?: BadgeProps
}

const DataBadgeField = ({
  colorScheme,
  label,
  labelTextProps,
  value,
  valueBadgeProps,
}: DataBadgeFieldProps) => {
  if (!value) return null
  return (
    <HStack spacing={2}>
      {label && (
        <Text
          fontSize='sm'
          color='black'
          fontWeight='semibold'
          {...labelTextProps}
        >
          {label}:
        </Text>
      )}
      <Badge
        borderRadius='4px'
        colorScheme={colorScheme}
        variant='solid'
        {...valueBadgeProps}
      >
        {value}
      </Badge>
    </HStack>
  )
}

export default DataBadgeField
