import { components, PlaceholderProps, GroupBase } from 'react-select'

const Input = ({
  children,
  ...props
}: PlaceholderProps<any, boolean, GroupBase<any>>) =>
  props.isFocused ? (
    <></>
  ) : (
    <components.Placeholder {...props}>{children}</components.Placeholder>
  )

export default Input
