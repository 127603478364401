import ReactMarkdown from 'react-markdown'
import ChakraMarkDownOverrides from './ChakraMarkDownOverrides'

const MarkDownContainer = ({ markdown }: { markdown: string }) => (
  <ReactMarkdown
    components={ChakraMarkDownOverrides}
    children={markdown}
    skipHtml
  />
)

export default MarkDownContainer
