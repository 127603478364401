const Alert = {
  parts: ['container', 'title', 'description', 'icon', 'spinner'],
  baseStyle: {
    container: {
      borderRadius: 'md',
    },
    description: {
      color: 'gray.700',
      lineHeight: '1.25rem',
    },
  },
}
export default Alert
