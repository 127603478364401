import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface BreadCrumbState {
  breadCrumbDetails: {
    isError?: boolean
    isLoading?: boolean
    name?: string
  } | null
}

const initialState: BreadCrumbState = {
  breadCrumbDetails: null,
}

export const breadCrumbSlice = createSlice({
  name: 'breadCrumb',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    cleanBreadcrumbDetails: (state) => {
      state.breadCrumbDetails = null
    },
    setBreadCrumbDetails: (state, action) => {
      state.breadCrumbDetails = action.payload
    },
  },
})

export const { cleanBreadcrumbDetails, setBreadCrumbDetails } =
  breadCrumbSlice.actions

export const selectBreadCrumbDetails = (state: RootState) =>
  state.breadCrumbDetailsState.breadCrumbDetails

export default breadCrumbSlice.reducer
