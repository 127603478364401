import {
  Box,
  Button,
  Text,
  useDimensions,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useLayoutEffect, useRef, useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

type SeeMoreTextProps = {
  maxHeight?: number
  text: string
}

const SeeMoreText = ({ maxHeight = 101, text }: SeeMoreTextProps) => {
  const { isOpen, onToggle } = useDisclosure()
  const elementRef = useRef(null)
  const elementDimensions = useDimensions(elementRef, true)
  const [startingHeight, setStartingHeight] = useState(maxHeight)

  const hasEnoughHeight =
    (elementDimensions?.borderBox?.height || 0) > maxHeight

  const textStyle =
    hasEnoughHeight && !isOpen
      ? {
          backgroundImage: 'linear-gradient(180deg, #4A5568, #FFFFFF)',
          backgroundClip: 'text',
        }
      : {
          color: 'gray.600',
        }

  useLayoutEffect(() => {
    if (elementDimensions && !hasEnoughHeight) {
      setStartingHeight(elementDimensions?.borderBox?.height || 0)
    }
  }, [hasEnoughHeight, setStartingHeight, elementDimensions])

  return (
    <VStack alignItems='flex-start'>
      <Box
        height={isOpen ? elementDimensions?.borderBox?.height : startingHeight}
        overflowY='hidden'
        // this prevents a jump effect in first render if text is not big enough
        transition={hasEnoughHeight ? 'height 0.3s ease-in-out' : 'none'}
      >
        <Text {...textStyle} ref={elementRef}>
          {text}
        </Text>
      </Box>
      {hasEnoughHeight && (
        <Button
          variant='outline'
          rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
          onClick={onToggle}
          alignSelf='center'
        >
          See {isOpen ? 'less' : 'more'}
        </Button>
      )}
    </VStack>
  )
}

export default SeeMoreText
