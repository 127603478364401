const hexToRgb = (hex: string, opacity: number = 1) => {
  // Remove the "#" symbol from the start of the hex string, if it exists
  hex = hex.replace(/^#/, '')

  // Parse the hex string into its RGB components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Return the RGB color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default hexToRgb
