import {
  DrawerHeader,
  Text,
  Icon,
  Flex,
  IconButton,
  Button,
} from '@chakra-ui/react'
import { BiX, BiLinkExternal } from 'react-icons/bi'

type SideModalHeaderProps = {
  title: string
  onClose: () => void
  onClickButton?: () => void
  isLoading?: boolean
}

const SideModalHeader = ({
  isLoading,
  onClickButton,
  onClose,
  title,
}: SideModalHeaderProps) => {
  return (
    <DrawerHeader paddingTop={4} paddingBottom={0} paddingInline={4}>
      <Flex alignItems='center' justifyContent='space-between' height='32px'>
        <Text color='gray.700' fontWeight='semibold' fontSize='md'>
          {title}
        </Text>
        <Flex alignItems='center'>
          {!!onClickButton && (
            <Button
              isLoading={isLoading}
              leftIcon={<BiLinkExternal />}
              marginRight={2.5}
              onClick={onClickButton}
              size='sm'
              variant='outline'
            >
              Go to edit page
            </Button>
          )}
          <IconButton
            aria-label='close'
            icon={<Icon as={BiX} boxSize='18px' color='gray.700' />}
            onClick={onClose}
            variant='modal-close-button'
          />
        </Flex>
      </Flex>
    </DrawerHeader>
  )
}

export default SideModalHeader
