const NumberInput = {
  parts: ['root', 'field', 'stepperGroup', 'stepper'],
  variants: {
    outline: {
      root: {
        _disabled: {
          bgColor: 'gray.100',
          cursor: 'not-allowed',
        },
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: 'gray.200',
      },
      field: {
        _focus: {
          borderColor: 'iris.500',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'red.500',
          color: 'red.600',
          boxShadow: 'none',
          _placeholder: { color: 'red.500' },
        },
        _disabled: {
          backgroundColor: 'gray.100',
          opacity: 0.5,
          cursor: 'not-allowed',
          color: 'gray.600',
          borderColor: 'gray.300',
        },
        _placeholder: {
          fontSize: 'sm',
          color: 'gray.500',
        },
        fontSize: 'sm',
      },
    },
  },
}

export default NumberInput
