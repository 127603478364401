import { useState, useRef, useEffect, useCallback } from 'react'

export type SortBy = Array<{ id: string; desc?: boolean }>

type UseTableControllersInitialParameters = {
  initialPageSize?: number
  initialPage?: number
  initialSortBy?: SortBy
}

const useTableControllers = (
  initialParameters?: UseTableControllersInitialParameters
) => {
  const { initialPage, initialPageSize, initialSortBy } =
    initialParameters || {}

  const debounceRef = useRef<NodeJS.Timeout>()
  const [currentPage, setCurrentPage] = useState(initialPage ?? 0)
  const [pageSize, setPageSize] = useState(initialPageSize ?? 50)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [sortBy, setSortBy] = useState(initialSortBy ?? [])
  const [searchValue, setSearchValue] = useState('')

  const onGoToPage = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  const onChangePageSize = useCallback((pageSize: number) => {
    setPageSize(pageSize)
    setCurrentPage(0)
  }, [])

  const onChangeSortBy = useCallback((sortBy: SortBy) => {
    setSortBy(sortBy)
  }, [])

  const onChangeGlobalFilterValue = useCallback(
    (value: string) => setSearchValue(value),
    []
  )

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(() => {
      setGlobalFilterValue(searchValue)
    }, 300)
  }, [searchValue, setGlobalFilterValue])

  return {
    currentPage,
    pageSize,
    globalFilterValue,
    sortBy,
    searchValue,
    onGoToPage,
    onChangePageSize,
    onChangeSortBy,
    onChangeGlobalFilterValue,
  }
}

export default useTableControllers
