import { Axios } from '@/commons/helpers'
import { CreateDemoClusterMutationResponse } from '@/commons/interfaces'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'

const createDemoCluster = () =>
  Axios.post<CreateDemoClusterMutationResponse>('/cluster/graphql', {
    query: jsonToGraphQLQuery({
      mutation: {
        createDemoCluster: {
          __on: [
            {
              __typeName: 'Cluster',
              clientId: true,
              __typename: true,
            },
            {
              __typeName: 'ClusterCouldNotBeDeployed',
              message: true,
              __typename: true,
            },
            {
              __typeName: 'ClusterNameInUse',
              message: true,
              __typename: true,
            },
            {
              __typeName: 'InvalidProviderInput',
              message: true,
              __typename: true,
            },
            {
              __typeName: 'UnexpectedBehavior',
              message: true,
              __typename: true,
            },
          ],
        },
      },
    }),
  }).then(({ data }) => {
    try {
      const typeName = data?.data?.createDemoCluster?.__typename
      if (typeName !== 'Cluster') {
        throw new Error(typeName)
      }
      return data.data.createDemoCluster
    } catch (error) {
      console.error(error)
      throw error
    }
  })

export { createDemoCluster }
