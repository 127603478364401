const Textarea = {
  baseStyle: {},
  sizes: {
    md: {
      _placeholder: {
        fontSize: 'sm',
      },
      fontSize: 'sm',
    },
  },
  defaultProps: {},
  variants: {
    outline: {
      _focus: {
        borderColor: 'iris.500',
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: 'red.500',
        color: 'red.600',
        boxShadow: 'none',
        _placeholder: {
          color: 'red.500',
        },
      },
      _disabled: {
        backgroundColor: 'gray.100',
        opacity: 0.5,
        cursor: 'not-allowed',
        color: 'gray.600',
        borderColor: 'gray.300',
      },
      _placeholder: {
        fontSize: 'sm',
        color: 'blackAlpha.500',
      },
      borderRadius: '8px',
      borderWidth: '1px',
      borderColor: 'gray.200',
    },
  },
}

export default Textarea
