import { ChangeEvent, useEffect } from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import { FieldInputProps as FormikFieldInputProps, FormikProps } from 'formik'
import { FieldBaseProps } from '@/commons/types'

export interface InnerInputProps extends FieldBaseProps {
  field: FormikFieldInputProps<any>
  focusOnMount?: boolean
  form: FormikProps<any>
  hasError: boolean
  innerRef: React.RefObject<HTMLInputElement>
  isValid?: boolean
  LeftElementComponent: any
  name: string
  onChange: (event: ChangeEvent<any>) => void
  placeholder?: string
  RightElementComponent: any
}

const InnerTextarea = ({
  field,
  focusOnMount,
  hasError,
  innerRef,
  isDisabled,
  isRequired,
  isValid,
  LeftElementComponent,
  name,
  onChange,
  placeholder,
  RightElementComponent,
  ...props
}: InnerInputProps & TextareaProps) => {
  useEffect(() => {
    if (focusOnMount) {
      innerRef?.current?.focus()
    }
  }, [innerRef, focusOnMount])

  return (
    <Textarea
      {...field}
      id={name}
      isDisabled={isDisabled}
      isRequired={isRequired}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default InnerTextarea
