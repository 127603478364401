import React, { ReactText } from 'react'
import { Flex, Link, FlexProps } from '@chakra-ui/react'
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom'

interface NavItemProps extends FlexProps {
  children: ReactText
  externalUrl?: string
  route: string
}
const NavItem = ({
  children,
  externalUrl,
  location,
  route,
}: NavItemProps & RouteComponentProps) => {
  const pathname = location.pathname

  return (
    <Link
      _focus={{ outline: 'none' }}
      as={RouterLink}
      style={{ textDecoration: 'none' }}
      to={route}
    >
      <Flex
        align='center'
        paddingY={2}
        paddingX={4}
        marginX={3}
        borderRadius='lg'
        cursor='pointer'
        _hover={{
          opacity: 0.8,
        }}
        fontWeight={pathname.includes(route) ? 'semibold' : 'normal'}
        fontSize='sm'
        color={pathname.includes(route) ? 'fluorescentBlue.500' : 'iris.200'}
        transition='opacity 0.5s ease'
        minWidth='180px'
      >
        {children}
      </Flex>
    </Link>
  )
}

export default NavItem
