import { Axios } from '@/commons/helpers'
import queryService from '@/commons/helpers/queryService'
import { MemberListResponse } from '@/commons/interfaces'
import { WatcherResponse } from '@/commons/interfaces/api/notifications/WatcherQueryResponse.interface'
import { GraphQLQueryVariables } from '@/commons/types'
import { uniq } from 'lodash'

const watcherFields = {
  id: true,
  userEmail: true,
  createdAt: true,
  path: true,
  alerts: {
    id: true,
    checked: true,
    createdAt: true,
    actions: {
      userEmail: true,
      path: true,
    },
  },
}

const fetchUserName = (userEmail: string) =>
  Axios.get<MemberListResponse>(
    `admin/management/organizations/members?search=${userEmail}`
  ).then((response) => ({
    name: response.data?.users[0]?.name,
    email: userEmail,
  }))

const hydrateAlertsWithUserName = async (
  watchers: WatcherResponse[] | undefined
) => {
  const alertsUserEmails = uniq(
    watchers?.reduce((acc, curr) => {
      let userEmails = curr.alerts.map((alert) => alert.actions.userEmail)
      return acc.concat([...userEmails])
    }, [] as string[])
  )
  if (watchers && alertsUserEmails && alertsUserEmails.length > 0) {
    const users = await Promise.allSettled(
      alertsUserEmails.map((userEmail) => fetchUserName(userEmail))
    ).then((results) => {
      const successfulFetches = results.filter(
        (user) => user.status === 'fulfilled' && !!user.value.name
      ) as PromiseFulfilledResult<{ name: string; email: string }>[]
      return successfulFetches.map((user) => user.value)
    })

    watchers.forEach((watcher) => {
      watcher.alerts.forEach((alert) => {
        alert.actions.userName =
          users.find((user) => user.email === alert.actions.userEmail)?.name ||
          alert.actions.userEmail
      })
    })
  }
}

const fetchWatchers = async (userEmail?: string) => {
  try {
    let variables: GraphQLQueryVariables<Partial<WatcherResponse>> = {
      first: 1000,
      after: 0,
      filters: { userEmail: { eq: userEmail } },
    }
    type ProvidedFields = keyof typeof watcherFields
    const watchers = await queryService<
      'watchers',
      WatcherResponse,
      ProvidedFields
    >({
      entityName: 'watchers',
      route: '/notifications/graphql',
      variables,
      queryFields: watcherFields,
      omitTotal: true,
      omitSubfilters: true,
    }).then((response) => response?.data)
    await hydrateAlertsWithUserName(watchers)
    return watchers
  } catch (error) {
    console.error(error)
  }
}

const checkAllAlerts = () =>
  Axios.post(`/notifications/graphql`, {
    query: `mutation CheckAllAlerts {
    markAlertsAsChecked(all: true) {
        message
      }
    }`,
  })

export { checkAllAlerts, fetchWatchers }
