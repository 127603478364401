import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import {
  UserResponse,
  JobScriptTemplateResponse,
  JobScriptResponse,
  ClusterResponse,
  JobSubmissionResponse,
  LicenseConfigurationResponse,
  GroupResponse,
} from '@/commons/interfaces'

export interface SideModalControllerState {
  clusterDialogData: (ClusterResponse & { clientSecret?: string }) | null
  clusterDialogOpen: boolean
  configDialogData: LicenseConfigurationResponse | null
  configDialogOpen: boolean
  groupDialogData: GroupResponse | null
  groupDialogOpen: boolean
  jobScriptDialogData: JobScriptResponse | null
  jobScriptDialogOpen: boolean
  jobSubmissionDialogData: JobSubmissionResponse | null
  jobSubmissionDialogOpen: boolean
  ownerDialogData: UserResponse | null
  ownerDialogOpen: boolean
  jobScriptTemplateDialogData: JobScriptTemplateResponse | null
  jobScriptTemplateDialogOpen: boolean
}

const initialState: SideModalControllerState = {
  clusterDialogData: null,
  clusterDialogOpen: false,
  configDialogData: null,
  configDialogOpen: false,
  groupDialogData: null,
  groupDialogOpen: false,
  jobScriptDialogData: null,
  jobScriptDialogOpen: false,
  jobSubmissionDialogData: null,
  jobSubmissionDialogOpen: false,
  ownerDialogData: null,
  ownerDialogOpen: false,
  jobScriptTemplateDialogData: null,
  jobScriptTemplateDialogOpen: false,
}

export const sideModalController = createSlice({
  name: 'sideModalController',
  initialState,
  reducers: {
    toggleClusterDialog: (state, action) => {
      state.clusterDialogOpen = !state.clusterDialogOpen
      state.clusterDialogData = action ? action.payload : null
    },
    toggleConfigDialog: (state, action) => {
      state.configDialogOpen = !state.configDialogOpen
      state.configDialogData = action ? action.payload : null
    },
    toggleGroupDialog: (state, action) => {
      state.groupDialogOpen = !state.groupDialogOpen
      state.groupDialogData = action ? action.payload : null
    },
    toggleJobScriptDialog: (state, action) => {
      state.jobScriptDialogOpen = !state.jobScriptDialogOpen
      state.jobScriptDialogData = action ? action.payload : null
    },
    toggleJobSubmissionDialog: (state, action) => {
      state.jobSubmissionDialogOpen = !state.jobSubmissionDialogOpen
      state.jobSubmissionDialogData = action ? action.payload : null
    },
    toggleOwnerDialog: (state, action) => {
      state.ownerDialogOpen = !state.ownerDialogOpen
      state.ownerDialogData = action ? action.payload : null
    },
    toggleJobScriptTemplateDialog: (state, action) => {
      state.jobScriptTemplateDialogOpen = !state.jobScriptTemplateDialogOpen
      state.jobScriptTemplateDialogData = action ? action.payload : null
    },
  },
})
export const {
  toggleClusterDialog,
  toggleConfigDialog,
  toggleGroupDialog,
  toggleJobScriptDialog,
  toggleJobSubmissionDialog,
  toggleOwnerDialog,
  toggleJobScriptTemplateDialog,
} = sideModalController.actions

export const selectClusterDialogData = (state: RootState) =>
  state.sideModalController.clusterDialogData

export const selectClusterDialogState = (state: RootState) =>
  state.sideModalController.clusterDialogOpen

export const selectGroupDialogData = (state: RootState) =>
  state.sideModalController.groupDialogData

export const selectGroupDialogState = (state: RootState) =>
  state.sideModalController.groupDialogOpen

export const selectJobScriptDialogData = (state: RootState) =>
  state.sideModalController.jobScriptDialogData

export const selectJobScriptDialogState = (state: RootState) =>
  state.sideModalController.jobScriptDialogOpen

export const selectJobSubmissionDialogData = (state: RootState) =>
  state.sideModalController.jobSubmissionDialogData

export const selectJobSubmissionDialogState = (state: RootState) =>
  state.sideModalController.jobSubmissionDialogOpen

export const selectLicenseConfigurationDialogData = (state: RootState) =>
  state.sideModalController.configDialogData

export const selectLicenseConfigurationDialogState = (state: RootState) =>
  state.sideModalController.configDialogOpen

export const selectOwnerDialogData = (state: RootState) =>
  state.sideModalController.ownerDialogData

export const selectOwnerDialogState = (state: RootState) =>
  state.sideModalController.ownerDialogOpen

export const selectJobScriptTemplateDialogState = (state: RootState) =>
  state.sideModalController.jobScriptTemplateDialogOpen

export const selectJobScriptTemplateDialogData = (state: RootState) =>
  state.sideModalController.jobScriptTemplateDialogData

export default sideModalController.reducer
