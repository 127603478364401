import { useCallback, useMemo } from 'react'
import { intersection, isEmpty } from 'lodash'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import jwtDecode from 'jwt-decode'
import { KeycloakTokenParsed } from '@/commons/types'

const useKeycloak = () => {
  const { isLoading, isAuthenticated, user, activeNavigator, ...auth } =
    useAuth()

  const parsedRefreshToken = useMemo(() => {
    if (!user?.refresh_token) return null
    return jwtDecode(user.refresh_token) as KeycloakTokenParsed
  }, [user?.refresh_token])

  const initialized = !hasAuthParams() && !activeNavigator && !isLoading

  // react-oidc-context doesn't provide permissions in user object
  const userPermissions = useMemo(() => {
    let permissions: string[] = []
    if (!user) return permissions
    try {
      const parsedToken = jwtDecode(user.access_token) as KeycloakTokenParsed
      permissions = parsedToken.permissions
    } catch (error) {
      console.error(error)
    }
    return permissions
  }, [user])

  const isUserAdmin = useMemo(() => {
    let isAdmin = false
    if (!user) return isAdmin
    try {
      isAdmin = userPermissions?.some((role) => role.split(':')[0] === 'admin')
    } catch (error) {
      console.error(error)
    }
    return isAdmin
  }, [user, userPermissions])

  const hasRolesInToken = useCallback(
    (roles: string[]) => {
      let hasRoles = false
      if (!user) return hasRoles
      try {
        hasRoles = intersection(userPermissions, roles).length === roles.length
      } catch (error) {
        console.error(error)
      }
      return hasRoles
    },
    [user, userPermissions]
  )

  const hasOrganizationInToken =
    initialized && isAuthenticated
      ? !isEmpty(user?.profile?.organization)
      : false

  return {
    activeNavigator,
    hasOrganizationInToken,
    hasRolesInToken,
    initialized,
    isAuthenticated,
    isLoading,
    isUserAdmin,
    user,
    parsedRefreshToken,
    ...auth,
  }
}

export default useKeycloak
