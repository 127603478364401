import {
  Flex,
  HStack,
  Text,
  Select,
  IconButton,
  Icon,
  chakra,
} from '@chakra-ui/react'
import {
  BiChevronRight,
  BiChevronLeft,
  BiChevronsLeft,
  BiChevronsRight,
} from 'react-icons/bi'

type TablePaginationProps = {
  currentPage: number
  goToPage: (page: number) => void
  isLoading?: boolean
  onChangePageSize: (pageSize: number) => void
  pageSize: number
  totalCount: number
}

const TablePagination = ({
  currentPage,
  goToPage,
  isLoading,
  onChangePageSize,
  pageSize,
  totalCount,
}: TablePaginationProps) => {
  const numberOfPages = Math.ceil(totalCount / pageSize)

  const handleGoToFirstPage = () => {
    goToPage(0)
  }

  const handleGoToLastPage = () => {
    goToPage(numberOfPages - 1)
  }

  const handleGoToNextPage = () => {
    if (currentPage < numberOfPages) goToPage(currentPage + 1)
  }

  const handleGoToPreviousPage = () => {
    if (currentPage > 0) goToPage(currentPage - 1)
  }

  return (
    <Flex justifyContent='space-between' width='100%'>
      <HStack>
        <Text whiteSpace='nowrap' fontSize='sm' color='gray.700'>
          Show rows per page
        </Text>
        <Select
          focusBorderColor='iris.500'
          iconColor='iris.500'
          color='gray.700'
          size='sm'
          onChange={(event) =>
            onChangePageSize(parseInt(event.target.value || '1'))
          }
          value={pageSize}
          borderColor='gray.200'
          borderRadius='6px'
          cursor='pointer'
        >
          <option>10</option>
          <option>15</option>
          <option>50</option>
          <option>100</option>
        </Select>
      </HStack>
      {!isLoading && (
        <HStack spacing={8}>
          <Text fontSize='sm' color='gray.700'>
            {currentPage + 1}{' '}
            <chakra.span color='gray.500'>of {numberOfPages}</chakra.span>
          </Text>
          <HStack spacing={4}>
            <IconButton
              _active={{
                backgroundColor: 'transparent',
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label='Go to first page'
              border='none'
              height='fit-content'
              icon={
                <Icon as={BiChevronsLeft} color='iris.500' boxSize='20.6px' />
              }
              isDisabled={currentPage === 0}
              minWidth='fit-content'
              onClick={handleGoToFirstPage}
              variant='ghost'
            />
            <IconButton
              _active={{
                backgroundColor: 'transparent',
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label='Previous page'
              border='none'
              height='fit-content'
              icon={
                <Icon as={BiChevronLeft} color='iris.500' boxSize='20.6px' />
              }
              isDisabled={currentPage === 0}
              minWidth='fit-content'
              onClick={handleGoToPreviousPage}
              variant='ghost'
            />
            <IconButton
              _active={{
                backgroundColor: 'transparent',
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label='Next page'
              border='none'
              height='fit-content'
              icon={
                <Icon as={BiChevronRight} color='iris.500' boxSize='20.6px' />
              }
              isDisabled={currentPage === numberOfPages - 1}
              minWidth='fit-content'
              onClick={handleGoToNextPage}
              variant='ghost'
            />
            <IconButton
              _active={{
                backgroundColor: 'transparent',
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label='Go to last page'
              border='none'
              height='fit-content'
              icon={
                <Icon as={BiChevronsRight} color='iris.500' boxSize='20.6px' />
              }
              isDisabled={currentPage === numberOfPages - 1}
              minWidth='fit-content'
              onClick={handleGoToLastPage}
              variant='ghost'
            />
          </HStack>
        </HStack>
      )}
    </Flex>
  )
}

export default TablePagination
