import { FormikValues } from 'formik'

export type Option = {
  label: string
  value: string
  [key: string]: any
}

type AsyncServiceArgs = {
  inputValue: string
  signal: AbortSignal | undefined
  values: FormikValues
}

type AsyncServicePromise = {
  service: ({
    values,
    signal,
    inputValue,
  }: Omit<AsyncServiceArgs, 'initialValue'>) => Promise<Option[]>
}

const asyncService = async ({
  inputValue,
  service,
  signal,
  values,
}: AsyncServiceArgs & AsyncServicePromise) => {
  try {
    const inputValueOptions = await service({ values, signal, inputValue })
    return inputValueOptions
  } catch (error) {
    console.error(error)
    return []
  }
}

export default asyncService
