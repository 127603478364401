import SeeMoreText from '@/components/atoms/SeeMoreText'
import { Stack, Text, TextProps } from '@chakra-ui/react'

type DataSeeMoreTextProps = {
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  maxHeight?: number
  value?: string
}

const DataSeeMoreText = ({
  isParagraph,
  label,
  labelTextProps,
  maxHeight,
  value,
}: DataSeeMoreTextProps) => {
  if (!value) return null
  return (
    <Stack
      spacing={isParagraph ? 1 : 2}
      direction={isParagraph ? 'column' : 'row'}
    >
      {label && (
        <Text
          fontSize='sm'
          color='black'
          fontWeight='semibold'
          {...labelTextProps}
        >
          {label}:
        </Text>
      )}
      <SeeMoreText text={value} maxHeight={maxHeight} />
    </Stack>
  )
}

export default DataSeeMoreText
