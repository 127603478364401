const apiBaseUrl = process.env.REACT_APP_BASE_URL as string
const keycloakClient = process.env.REACT_APP_KEYCLOAK_CLIENT as string
const keycloakRedirectUrl = process.env.REACT_APP_REDIRECT_URL as string
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL as string
const privacyPageDocumentUrl = process.env.REACT_APP_POLICY_FILE as string
const reactAppEnv = process.env.REACT_APP_ENV as string
const reactAppVersion = process.env.REACT_APP_VERSION as string

export {
  apiBaseUrl,
  keycloakClient,
  keycloakRedirectUrl,
  keycloakUrl,
  privacyPageDocumentUrl,
  reactAppEnv,
  reactAppVersion,
}
