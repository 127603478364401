import { chakra, HStack, Text, VStack } from '@chakra-ui/react'
import { DateTime } from 'luxon'

type CommentProps = {
  author: string
  text: string
  date: string
}

const Comment = ({ author, date, text }: CommentProps) => (
  <VStack
    backgroundColor='gray.50'
    padding={4}
    alignItems='flex-start'
    spacing={3}
    borderRadius='lg'
  >
    <HStack width='100%' justify='space-between'>
      <Text color='black' fontWeight='semibold'>
        {author}{' '}
        <chakra.span fontWeight='normal' color='gray.500'>
          commented
        </chakra.span>
      </Text>
      <Text color='gray.500'>{DateTime.fromISO(date).toFormat('DD - TT')}</Text>
    </HStack>
    <Text color='gray.600'>{text}</Text>
  </VStack>
)

export default Comment
