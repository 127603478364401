import { TextProps } from '@chakra-ui/react'
import { TextFieldComponent } from '@/components/atoms'

type DataTextFieldProps = {
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  overflowEllipsis?: boolean
  value?: string | number
  valueTextProps?: TextProps
}

const DataTextField = ({
  isParagraph,
  label,
  labelTextProps,
  overflowEllipsis,
  value,
  valueTextProps,
}: DataTextFieldProps) => {
  if (!value) return null
  return (
    <TextFieldComponent
      isParagraph={isParagraph}
      label={label}
      labelTextProps={labelTextProps}
      overflowEllipsis={overflowEllipsis}
      value={value}
      valueTextProps={valueTextProps}
    />
  )
}

export default DataTextField
