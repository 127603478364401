import { ReactNode } from 'react'
import { get } from 'lodash'
import {
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { Field, FieldProps as FormikFieldProps, FormikProps } from 'formik'
import { FieldBaseProps } from '@/commons/types'
import { FieldLabel } from '@/components/atoms'

export type RadioOption = {
  label: string | ReactNode
  value: string
  shouldDisable?: (formikProps: FormikProps<any>) => boolean
}

export interface FieldRadioGroupProps extends FieldBaseProps {
  direction?: 'row' | 'column'
  onChange?: (nextValue: string, formikProps: FormikProps<any>) => void
  options: RadioOption[]
  OptionComponent?: ({
    label,
    shouldDisable,
    value,
  }: RadioOption) => JSX.Element
}

const FieldRadioGroup = ({
  direction = 'column',
  label,
  LabelComponent,
  labelProps,
  name,
  onChange,
  OptionComponent,
  options,
  subLabel,
  tooltipMessage,
}: FieldRadioGroupProps) => (
  <Field name={name}>
    {({ field, form }: FormikFieldProps) => {
      const errorMessage = get(form, `errors.${name}`),
        hasError = !!(errorMessage && get(form, `touched.${name}`))

      const handleChange = (nextValue: string) =>
        onChange
          ? onChange(nextValue, form)
          : form.setFieldValue(name, nextValue)

      return (
        <FormControl isInvalid={hasError}>
          {label && (
            <FieldLabel
              hasError={hasError}
              label={label}
              LabelComponent={LabelComponent}
              labelProps={labelProps}
              name={name}
              subLabel={subLabel}
              tooltipMessage={tooltipMessage}
            />
          )}

          <RadioGroup onChange={handleChange} value={field.value}>
            <Stack direction={direction} spacing={0}>
              {options.map(({ label, shouldDisable, value }) =>
                OptionComponent ? (
                  <OptionComponent
                    key={value}
                    label={label}
                    shouldDisable={shouldDisable}
                    value={value}
                  />
                ) : (
                  <Radio
                    key={value}
                    value={value}
                    isDisabled={shouldDisable && shouldDisable(form)}
                  >
                    {label}
                  </Radio>
                )
              )}
            </Stack>
          </RadioGroup>
          <FormErrorMessage ml='2ch'>{errorMessage}</FormErrorMessage>
        </FormControl>
      )
    }}
  </Field>
)

export default FieldRadioGroup
