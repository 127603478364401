import { Box, Text, TextProps } from '@chakra-ui/react'
import { TextFieldComponent } from '@/components/atoms'

type TextListProps = {
  label?: string
  labelTextProps?: TextProps
  value?: string | number
  valueTextProps?: TextProps
}

type DataTextListFieldProps = Omit<
  TextListProps,
  'value' | 'valueTextProps'
> & {
  value?: TextListProps[]
}

const DataTextListField = ({
  label,
  labelTextProps,
  value,
}: DataTextListFieldProps) => {
  if (!value || value.length === 0) return null
  return (
    <Box>
      {label && (
        <Text
          fontSize='sm'
          color='black'
          fontWeight='semibold'
          {...labelTextProps}
        >
          {label}:
        </Text>
      )}
      {value.map((valueRow, index) => (
        <TextFieldComponent key={index} {...valueRow} />
      ))}
    </Box>
  )
}

export default DataTextListField
