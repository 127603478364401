import { chakra, HStack, VStack } from '@chakra-ui/react'
import AvatarWithInitials from '@/components/atoms/AvatarWithInitials'

type MenuSectionHeaderProps = {
  fallbackName?: string
  sectionSubtitle?: string
  sectionTitle?: string
  src?: string
}

const MenuSectionHeader = ({
  fallbackName,
  sectionSubtitle,
  sectionTitle,
  src,
}: MenuSectionHeaderProps) => (
  <HStack spacing={4} marginBottom={2}>
    <AvatarWithInitials src={src} name={fallbackName} size='md' />
    <VStack alignItems='flex-start' spacing={0}>
      <chakra.span
        fontWeight='semibold'
        fontSize='md'
        textTransform='capitalize'
      >
        {sectionTitle}
      </chakra.span>
      {!!sectionSubtitle && (
        <chakra.span fontSize='xs' color='gray.600'>
          {sectionSubtitle}
        </chakra.span>
      )}
    </VStack>
  </HStack>
)

export default MenuSectionHeader
