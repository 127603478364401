import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@fontsource/montserrat'
import '@fontsource/open-sans'

import Guardian from '@/components/organisms/Guardian'

import theme from '@/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Guardian />
      </ChakraProvider>
    </QueryClientProvider>
  )
}

export default App
