import {
  chakra,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Icon,
  Tooltip,
  Box,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { BiDotsHorizontal } from 'react-icons/bi'

type RowAction = {
  label: string
  action: any
  icon?: IconType
  color?: string
  isHidden?: boolean
  isDisabled?: boolean
  tooltipDisabledMessage?: string
}

interface TableActionsProps {
  actions:
    | Array<RowAction>
    | {
        action: any
        ariaLabel: string
        color?: string
        icon: IconType
        tooltipLabel: string
        isDisabled?: boolean
      }
}

const TableActions = ({ actions }: TableActionsProps) => (
  <chakra.div
    // prevents that the event reaches to tr onClick function
    onClick={(event) => {
      event.stopPropagation()
    }}
    display='flex'
    justifyContent='flex-end'
  >
    {!Array.isArray(actions) && (
      <Tooltip
        label={actions.tooltipLabel}
        hasArrow
        placement='top-end'
        gutter={0}
        padding={2}
        lineHeight='16px'
      >
        <Box>
          <IconButton
            _active={{
              backgroundColor: 'transparent',
            }}
            _hover={{
              backgroundColor: 'transparent',
            }}
            _disabled={{
              backgroundColor: 'transparent',
              opacity: 0.5,
            }}
            aria-label={actions.tooltipLabel}
            backgroundColor='transparent'
            color={actions.color}
            icon={<Icon as={actions.icon} boxSize='16px' />}
            isDisabled={actions.isDisabled}
            onClick={actions.action}
            size='sm'
          />
        </Box>
      </Tooltip>
    )}
    {Array.isArray(actions) && (
      <Menu autoSelect={false}>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<Icon as={BiDotsHorizontal} color='tableEllipsis' />}
          variant='ghost'
          height='25px'
          minWidth='25px'
        />
        <MenuList boxShadow='md' borderRadius='8px'>
          {actions
            .filter(({ isHidden }) => !isHidden)
            .map(
              (
                {
                  label,
                  action,
                  icon,
                  color,
                  isDisabled,
                  tooltipDisabledMessage,
                }: RowAction,
                index: number
              ) => (
                <Tooltip
                  gutter={0}
                  hasArrow
                  isDisabled={!isDisabled}
                  key={index}
                  label={tooltipDisabledMessage}
                  lineHeight='16px'
                  padding={2}
                  placement='top-end'
                >
                  <Box>
                    <MenuItem
                      color={color}
                      icon={
                        Icon ? (
                          <Icon
                            as={icon}
                            boxSize={4}
                            verticalAlign='middle'
                            marginTop='-4px'
                          />
                        ) : (
                          <></>
                        )
                      }
                      onClick={action}
                      fontSize='sm'
                      height='fit-content'
                      isDisabled={isDisabled}
                    >
                      {label}
                    </MenuItem>
                  </Box>
                </Tooltip>
              )
            )}
        </MenuList>
      </Menu>
    )}
  </chakra.div>
)

export default TableActions
