import { Button, ButtonProps } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

export type AsyncActionButtonProps = {
  onClickPromise: () => Promise<any>
  children: React.ReactNode
  initiallyLoading?: boolean
}

const AsyncActionButton = ({
  onClickPromise,
  children,
  initiallyLoading,
  ...props
}: AsyncActionButtonProps & ButtonProps) => {
  const [isLoading, setIsLoading] = useState(initiallyLoading)

  const handleClick = useCallback(() => {
    setIsLoading(true)
    onClickPromise().finally(() => setIsLoading(false))
  }, [onClickPromise])

  return (
    <Button {...props} isLoading={isLoading} onClick={handleClick}>
      {children}
    </Button>
  )
}

export default AsyncActionButton
