const tagStylesByVariant = {
  blue: {
    backgroundColor: 'blue.50',
    valueColor: 'blue.600',
  },
  gray: {
    backgroundColor: 'gray.100',
    valueColor: 'gray.600',
  },
  green: {
    backgroundColor: 'green.100',
    valueColor: 'green.600',
  },
  orange: {
    backgroundColor: 'orange.100',
    valueColor: 'orange.600',
  },
  purple: {
    backgroundColor: 'purple.50',
    valueColor: 'purple.600',
  },
  red: {
    backgroundColor: 'red.100',
    valueColor: 'red.600',
  },
  teal: {
    backgroundColor: 'teal.50',
    valueColor: 'teal.600',
  },
} as const

export { tagStylesByVariant }
