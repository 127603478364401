import { Axios } from '@/commons/helpers'

const clearAlert = (alertId: number) =>
  Axios.post(`/notifications/graphql`, {
    query: `mutation ClearAlert {
    markAlertsAsChecked(ids: ${[alertId]}) {
        message
        }
    }`,
  })

export { clearAlert }
