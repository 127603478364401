import axios from 'axios'
import { apiBaseUrl } from '@/commons/helpers/LocalEnvData'
import { logout } from '@/store/guardianSlice'
import { store } from '@/store/store'
import UserManagerClient from './UserManagerClient'

const UNAUTHENTICATED = 'unauthenticated',
  REQUEST_CANCELED = 'request canceled'

axios.interceptors.request.use(async (request) => {
  const user = await UserManagerClient.getUser()

  if (!!request?.headers && !!user?.access_token) {
    request.baseURL = apiBaseUrl
    request.headers['Content-Type'] = 'application/json'
    request.headers['Access-Control-Allow-Origin'] = '*'
    request.headers['Authorization'] = `Bearer ${user.access_token}`
  }
  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logout())
      return Promise.reject(UNAUTHENTICATED)
    }

    if (axios.isCancel(error)) {
      return Promise.reject(REQUEST_CANCELED)
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response)
    }

    return Promise.reject(error.toString())
  }
)

export default axios
