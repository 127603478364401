import { HStack, Text, TextProps } from '@chakra-ui/react'
import { ActionFieldComponent } from '@/components/atoms'

export type ActionComponent = {
  ariaLabel: string
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  isDisabled?: boolean
  isHidden?: boolean
  isOpen?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  tooltipLabel: string
}

type ActionFieldComponentProps = {
  label?: string
  labelTextProps?: TextProps
  leftActions?: ActionComponent[]
  overflowEllipsis?: boolean
  rightActions?: ActionComponent[]
  value?: string | number
  valueTextProps?: TextProps
}

const makeValueStyle = (
  valueTextProps?: TextProps,
  overflowEllipsis?: boolean
) => {
  let valueTextPropsCopy = valueTextProps ?? {}
  if (overflowEllipsis) {
    valueTextPropsCopy.overflow = 'hidden'
    valueTextPropsCopy.textOverflow = 'ellipsis'
    valueTextPropsCopy.whiteSpace = 'nowrap'
  }

  return valueTextPropsCopy
}

const DataActionField = ({
  label,
  labelTextProps,
  leftActions,
  overflowEllipsis,
  rightActions,
  value,
  valueTextProps,
}: ActionFieldComponentProps) => {
  if (!value && !leftActions?.length && !rightActions?.length) return null
  return (
    <HStack spacing={2}>
      {!!label && (
        <Text fontWeight='semibold' {...labelTextProps}>
          {label}:
        </Text>
      )}
      {leftActions?.map((action, index) => (
        <ActionFieldComponent key={index} {...action} />
      ))}
      {!!value && (
        <Text
          color='iris.500'
          fontWeight='semibold'
          {...makeValueStyle(valueTextProps, overflowEllipsis)}
        >
          {value}
        </Text>
      )}
      {rightActions?.map((action, index) => (
        <ActionFieldComponent key={index} {...action} />
      ))}
    </HStack>
  )
}

export default DataActionField
