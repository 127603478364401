const Radio = {
  parts: ['label', 'container', 'control'],
  sizes: {
    md: {
      label: {
        fontSize: 'sm',
      },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'iris',
  },
}

export default Radio
