import {
  AlertResponse,
  WatcherResponse,
} from '@/commons/interfaces/api/notifications/WatcherQueryResponse.interface'

const sortAlertsByDate = (alerts: AlertResponse[]) => {
  return alerts.sort((a, b) => {
    const dateA = new Date(a.createdAt!)
    const dateB = new Date(b.createdAt!)
    return dateB.getTime() - dateA.getTime()
  })
}

const makeAlertsFromWatchers = (watchers?: WatcherResponse[]) => {
  if (!watchers) return []
  return sortAlertsByDate(
    watchers?.reduce(
      (acc, curr) => acc.concat(curr.alerts),
      [] as AlertResponse[]
    )
  )
}

export { sortAlertsByDate, makeAlertsFromWatchers }
