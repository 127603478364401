import { Button, Icon, chakra } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { BiPlusCircle } from 'react-icons/bi'

export type AddDropdownOption = { label: string; value: string }

type AddGroupButtonItemProps = {
  handleAddOptionToDraft: (option: AddDropdownOption) => void
  option: AddDropdownOption
  children: ReactNode
}

const AddGroupButtonItem = ({
  handleAddOptionToDraft,
  option,
  children,
}: AddGroupButtonItemProps) => (
  <Button
    borderRadius={0}
    justifyContent='space-between'
    variant='ghost'
    width='100%'
    rightIcon={<Icon color='iris.500' as={BiPlusCircle} boxSize={5} />}
    onClick={() => handleAddOptionToDraft(option)}
  >
    <chakra.span
      overflow='hidden'
      whiteSpace='nowrap'
      textOverflow='ellipsis'
      width='85%'
      textAlign='left'
    >
      {children}
    </chakra.span>
  </Button>
)

export default AddGroupButtonItem
