import { SearchInput, TableSelector } from '@/components/atoms'
import { Box, Wrap } from '@chakra-ui/react'

type TableSearchSelectorsProps = {
  onChangeSearch: (value: string) => void
  searchValue: string
  searchPlaceholder?: string
  selectors?: {
    selectorName: string
    selectorValue: string
    onChange: (value: string) => void
    selectorOptions: string[]
  }[]
}

const TableSearchSelectors = ({
  onChangeSearch,
  searchValue,
  searchPlaceholder,
  selectors,
}: TableSearchSelectorsProps) => (
  <Wrap width='100%' spacing={2.5}>
    {/* arbitrary big number to force search input width the higher possible */}
    <Box flexGrow={1000}>
      <SearchInput
        value={searchValue}
        onChange={onChangeSearch}
        placeholder={searchPlaceholder}
      />
    </Box>
    {Array.isArray(selectors) && (
      <Wrap flexGrow={1}>
        {selectors.map((selector, index) => (
          <TableSelector key={index} {...selector} />
        ))}
      </Wrap>
    )}
  </Wrap>
)

export default TableSearchSelectors
