import { extendTheme, theme } from '@chakra-ui/react'

// Global fonts usage
import fonts from './fonts'

// Global style overrides
import styles from './styles'

// Global colors usage
import colors from './colors'

// Components overrides
import * as components from './components'

const overrides = {
  colors,
  fonts,
  styles,
  components: { ...components },
  fontSizes: {
    ...theme.fontSizes,
    xxs: '0.625rem',
  },
}

export default extendTheme(overrides)
