import React from 'react'
import {
  VStack,
  Button,
  Flex,
  Box,
  IconButton,
  StackProps,
} from '@chakra-ui/react'
import { FieldArray, FormikValues } from 'formik'
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi'

import { FieldBaseProps } from '@/commons/types/FieldBaseProps.type'

import InnerFieldMultiInputs from './components/InnerFieldMultiInputs'
import { FieldLabel } from '@/components/atoms'

interface OptionalInputFields {
  inputFields?: string
}

interface RequiredInputFields<InputType> {
  inputFields: InputType
}

export interface IRowComponentProps<InputType> {
  index: number
  remove: (index: number) => void
  fieldValues: InputType[]
  lineErrors:
    | (InputType extends Object ? { [key in keyof InputType]: string } : string)
    | undefined
  push: (value: InputType) => void
}

// The usage of FormValues is to type FormikProps in InnerFieldMultiInputs
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FieldMultiInputsCommons<FormValues extends FormikValues, InputType>
  extends FieldBaseProps {
  addNewLabel?: string
  allowRemoveAll?: boolean
  hideLineAddButton?: boolean
  hideLineRemoveButton?: boolean
  rowSpacing?: StackProps['spacing']
  RowComponent: React.FC<IRowComponentProps<InputType>>
}

export type FieldMultiInputsProps<
  FormValues extends FormikValues,
  InputType
> = FieldMultiInputsCommons<FormValues, InputType> &
  (InputType extends string
    ? OptionalInputFields
    : RequiredInputFields<InputType>)

export const RemoveLineButton = ({
  isDisabled,
  onClick,
}: {
  isDisabled?: boolean
  onClick: () => void
}) => (
  <IconButton
    _active={{
      backgroundColor: 'gray.200',
    }}
    _hover={{
      backgroundColor: 'gray.100',
      color: 'iris.500',
    }}
    aria-label='remove item'
    backgroundColor='iris.500'
    borderRadius='full'
    color='white'
    icon={<BiMinusCircle />}
    isDisabled={isDisabled}
    onClick={onClick}
    size='sm'
    variant='solid'
  />
)

const FieldMultiInputs = <FormValues extends FormikValues, InputType = string>({
  addNewLabel,
  allowRemoveAll,
  hideLineAddButton,
  hideLineRemoveButton,
  inputFields,
  label,
  labelProps,
  rowSpacing = 3,
  name,
  RowComponent,
  tooltipMessage,
}: FieldMultiInputsProps<FormValues, InputType>) => {
  return (
    <Box width='100%'>
      <FieldLabel
        tooltipMessage={tooltipMessage}
        label={label}
        name={name}
        labelProps={labelProps}
      />
      <FieldArray
        name={name}
        render={({ form, remove, push }) => (
          <VStack alignItems='flex-start' spacing={rowSpacing}>
            <InnerFieldMultiInputs<FormValues, InputType>
              allowRemoveAll={allowRemoveAll}
              formProps={form}
              hideLineRemoveButton={hideLineRemoveButton}
              name={name}
              remove={remove}
              RowComponent={RowComponent}
              push={push}
            />
            {!hideLineAddButton && (
              <Flex width='100%' justifyContent='flex-start'>
                <Button
                  leftIcon={<BiPlusCircle />}
                  variant='ghost'
                  fontSize='sm'
                  onClick={() => (inputFields ? push(inputFields) : push(''))}
                  color='iris.500'
                  _active={{
                    backgroundColor: 'gray.200',
                  }}
                  _hover={{
                    backgroundColor: 'gray.100',
                  }}
                >
                  {addNewLabel}
                </Button>
              </Flex>
            )}
          </VStack>
        )}
      />
    </Box>
  )
}

export default React.memo(
  FieldMultiInputs,
  (prev, next) => prev.name === next.name
) as typeof FieldMultiInputs
