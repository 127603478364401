import { components, OptionProps, GroupBase } from 'react-select'
import { BoldQuery } from '@/components/atoms'
import { chakra } from '@chakra-ui/react'

type AsyncOption = OptionProps<any, boolean, GroupBase<any>> & {
  inputValue: string
}

const Option = ({ children, inputValue, ...props }: AsyncOption) => (
  <components.Option {...props}>
    <chakra.span>
      {inputValue ? (
        <BoldQuery query={inputValue} str={props.data.label} />
      ) : (
        props.data.label
      )}
    </chakra.span>
  </components.Option>
)

export default Option
