/* 
    This hook is projected to work with the details page
    It will receive all the arguments from a normal usage of useQuery
    and will return the same result, but with the addition of the breadcrumb.
*/

import { get } from 'lodash'
import { useQuery, UseQueryOptions } from 'react-query'
import useBreadCrumb from './useBreadCrumb'

const useQueryWithBreadcrumb = <T>(
  breadcrumbField: string,
  { queryKey, queryFn, ...options }: UseQueryOptions<T>
) => {
  const useQueryObject = useQuery<T>({
    queryKey,
    queryFn,
    ...options,
  })
  const { isFetching, data, isError } = useQueryObject
  useBreadCrumb({
    name: get(data, breadcrumbField, '') as string,
    isLoading: isFetching,
    isError,
  })

  return useQueryObject
}

export default useQueryWithBreadcrumb
