import { HStack, Button } from '@chakra-ui/react'

type ActionButtonsProps = {
  cancelVariant?: string
  isDisabled?: boolean
  isSubmitting?: boolean
  onClickCancel: () => void
  onClickSubmit?: () => void
  submitType?: 'submit' | 'button' | 'reset'
  submitVariant?: string
  cancelLabel?: string
  submitLabel?: string
}

const ActionButtons = ({
  cancelLabel = 'Cancel',
  cancelVariant = 'ghost',
  isDisabled,
  isSubmitting,
  onClickCancel,
  onClickSubmit,
  submitLabel = 'Save',
  submitType = 'submit',
  submitVariant = 'solid',
}: ActionButtonsProps) => (
  <HStack marginTop={4} justifyContent='flex-end'>
    <Button
      onClick={onClickCancel}
      isDisabled={isSubmitting}
      variant={cancelVariant}
    >
      {cancelLabel}
    </Button>
    <Button
      isDisabled={isDisabled}
      isLoading={isSubmitting}
      onClick={onClickSubmit}
      type={submitType}
      variant={submitVariant}
    >
      {submitLabel}
    </Button>
  </HStack>
)

export default ActionButtons
