import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import {
  chakra,
  Box,
  HStack,
  Icon,
  IconButton,
  keyframes,
  Image,
} from '@chakra-ui/react'

import { BiChevronsLeft } from 'react-icons/bi'

import Logotype from '@/assets/images/Logotype.svg'
import MinimizedLogotype from '@/assets/images/MinimizedLogotype.svg'
import { reactAppVersion } from '@/commons/helpers/LocalEnvData'

import useSidebarControl from './useSidebarControl'
import SidebarSubSection from './components/SidebarSubSection'
import { DashboardItems, SectionItems } from './contentListItems'

const animationInKeyframes = keyframes`
  0% {
    width: 78px
  }
  100% {
    width: 260px
  }`

const animationOutKeyFrames = keyframes`
  0% {
    width: 260px
  }
  100% {
    width: 78px
  }`

const animationIn = `${animationInKeyframes} 0.150s ease-in`
const animationOut = `${animationOutKeyFrames} 0.150s ease-in`

const versionStyle = {
  bottom: '1rem',
  color: 'iris.200',
  fontSize: 'xs',
  minWidth: '70px',
  transition: 'opacity 0.5s ease',
}

const SidebarContent = () => {
  const history = useHistory()
  const {
    hoverRef,
    isFixedOpen,
    onChangeAccordionItem,
    onToggleSidebarState,
    openAccordionItems,
    isSidebarOpen,
  } = useSidebarControl(SectionItems)

  const appsItems = SectionItems.filter((item) => item.subSection === 'apps')
  const helpdeskItems = SectionItems.filter(
    (item) => item.subSection === 'helpdesk'
  )

  return (
    <Box
      animation={isSidebarOpen ? animationIn : animationOut}
      as={motion.div}
      backgroundColor='navigationBackground'
      color='white'
      height='full'
      overflow='hidden'
      paddingTop={5}
      paddingX={4}
      position='fixed'
      ref={hoverRef}
      width={isSidebarOpen ? '260px' : '78px'}
      zIndex={25}
    >
      <HStack spacing={0} justifyContent='space-between'>
        <Box cursor='pointer' onClick={() => history.push('/')}>
          <Image
            maxWidth='none'
            src={Logotype}
            display={isSidebarOpen ? 'block' : 'none'}
            htmlWidth='137px'
          />
          <Image
            maxWidth='none'
            src={MinimizedLogotype}
            display={isSidebarOpen ? 'none' : 'block'}
          />
        </Box>
        {isSidebarOpen && (
          <IconButton
            _active={{
              backgroundColor: 'iris.900',
            }}
            _hover={{
              backgroundColor: 'iris.800',
            }}
            aria-label='toggle sidebar'
            backgroundColor='iris.700'
            borderRadius='full'
            icon={
              <Icon
                verticalAlign='middle'
                as={BiChevronsLeft}
                boxSize={4}
                transform={isFixedOpen ? 'unset' : 'rotate(-180deg)'}
              />
            }
            onClick={onToggleSidebarState}
            size='sm'
          />
        )}
      </HStack>
      <SidebarSubSection
        isSidebarOpen={isSidebarOpen}
        onChangeAccordionItem={onChangeAccordionItem}
        openAccordionItems={openAccordionItems}
        SubSectionItems={DashboardItems}
      />
      <SidebarSubSection
        isSidebarOpen={isSidebarOpen}
        onChangeAccordionItem={onChangeAccordionItem}
        openAccordionItems={openAccordionItems}
        SubSectionItems={appsItems}
      />
      <SidebarSubSection
        isSidebarOpen={isSidebarOpen}
        onChangeAccordionItem={onChangeAccordionItem}
        openAccordionItems={openAccordionItems}
        SubSectionItems={helpdeskItems}
      />
      {/* in order to create a good transition effect, both spans need to be overlapping */}
      <Box>
        <chakra.span
          {...versionStyle}
          opacity={isSidebarOpen ? 1 : 0}
          position='absolute'
        >
          Version {reactAppVersion}
        </chakra.span>
        <chakra.span
          {...versionStyle}
          opacity={isSidebarOpen ? 0 : 1}
          position='absolute'
        >
          V {reactAppVersion}
        </chakra.span>
      </Box>
    </Box>
  )
}

export default SidebarContent
