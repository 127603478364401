import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Flex, VStack, Box, Text, Button } from '@chakra-ui/react'

import { ClusterEmptyStateIcon } from '@/components/atoms'
import { useToasts } from '@/hooks'
import { createDemoCluster } from './services'

const ClusterEmptyStateCTA = () => {
  const history = useHistory()
  const { renderErrorToast, renderSuccessToast } = useToasts({
    errorDescription: 'Error creating demo cluster',
    successDescription: 'Demo cluster created successfully',
  })

  const { mutate, isLoading } = useMutation(
    'prepareDemoCluster',
    createDemoCluster,
    {
      onSuccess: (data) => {
        renderSuccessToast()
        history.push(`/cluster/${data.clientId}`)
      },
      onError: (error) => {
        console.error(error)
        renderErrorToast()
      },
    }
  )

  const handleCreateDemoCluster = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <Flex justify='center' alignItems='center' h='40vh'>
      <VStack spacing={3}>
        <ClusterEmptyStateIcon />
        <Box textAlign='center' width='300px'>
          <Text color='black' fontWeight='semibold'>
            Try Vantage with a Demo Cluster!
          </Text>
          <Text color='gray.500' fontSize='xs'>
            Launch a demo cluster that runs right on your desktop to try out all
            the features of Vantage.
          </Text>
        </Box>
        <Button onClick={handleCreateDemoCluster} isLoading={isLoading}>
          Prepare Demo Cluster
        </Button>
      </VStack>
    </Flex>
  )
}

export default ClusterEmptyStateCTA
