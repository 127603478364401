import { useState, useRef, useLayoutEffect } from 'react'

const useHover = <T>(
  debounceTime?: number
): [React.MutableRefObject<T>, boolean] => {
  const [value, setValue] = useState<boolean>(false)
  const ref: any = useRef<T | null>(null)
  const debounceTimeout = useRef<NodeJS.Timeout>()
  const handleMouseOver = (): void => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      setValue(true)
    }, debounceTime || 0)
  }
  const handleMouseLeave = (): void => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      setValue(false)
    }, debounceTime || 0)
  }
  useLayoutEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseleave', handleMouseLeave)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  )
  return [ref, value]
}

export default useHover
