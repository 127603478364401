import { useCallback, useMemo, useState } from 'react'
import { Avatar, AvatarProps } from '@chakra-ui/react'
import { formatNames, getAvatarFallbackBgColor } from '@/commons/helpers'

const getBgColor = ({
  isLoadingImage,
  isImgLoadedSuccess,
  formattedName,
}: {
  isLoadingImage: boolean
  isImgLoadedSuccess: boolean
  formattedName: string
}) => {
  if (isLoadingImage) return 'gray.100'
  if (isImgLoadedSuccess) return 'transparent'
  return getAvatarFallbackBgColor(formattedName)
}

const AvatarWithInitials = ({ name, src, ...props }: AvatarProps) => {
  const [isLoadingImage, setIsLoadingImage] = useState(!!src)
  const [isImgLoadedSuccess, setIsImgLoadedSuccess] = useState(false)

  const handleLoad = useCallback(() => {
    setIsImgLoadedSuccess(true)
    setIsLoadingImage(false)
  }, [setIsImgLoadedSuccess, setIsLoadingImage])

  const handleError = useCallback(() => {
    setIsImgLoadedSuccess(false)
    setIsLoadingImage(false)
  }, [setIsImgLoadedSuccess, setIsLoadingImage])

  const formattedName = useMemo(() => formatNames(name), [name])
  const bgColor = useMemo(
    () =>
      getBgColor({
        isLoadingImage,
        isImgLoadedSuccess,
        formattedName,
      }),
    [isLoadingImage, isImgLoadedSuccess, formattedName]
  )

  return (
    <Avatar
      name={formattedName}
      src={src}
      onLoad={handleLoad}
      onError={handleError}
      backgroundColor={bgColor}
      {...props}
    />
  )
}

export default AvatarWithInitials
