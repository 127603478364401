import {
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagLabel,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { BiInfoCircle, BiWindows } from 'react-icons/bi'
import { GroupResponse } from '@/commons/interfaces'
import { useAppDispatch } from '@/hooks'
import { toggleGroupDialog } from '@/store/sideModalControllerSlice'

type GroupTagProps = {
  group: GroupResponse
  hidePreview?: boolean
  hideTooltip?: boolean
}

const GroupTag = ({ group, hidePreview, hideTooltip }: GroupTagProps) => {
  const dispatch = useAppDispatch()
  return (
    <Tag
      size='sm'
      borderRadius='full'
      variant='solid'
      backgroundColor='gray.200'
      color='gray.700'
      fontWeight='semibold'
      fontSize='xs'
    >
      <TagLabel>
        <HStack spacing={1}>
          {!hideTooltip && (
            <Popover isLazy trigger='hover' placement='top-end' variant='info'>
              <PopoverTrigger>
                <Flex>
                  <Icon as={BiInfoCircle} color='gray.700' boxSize='14px' />
                </Flex>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <VStack alignItems='flex-start'>
                    {Array.isArray(group.roles) &&
                      group.roles.map((role) => (
                        <chakra.span key={role}>{role}</chakra.span>
                      ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}

          <chakra.span
            lineHeight='1rem'
            marginInlineEnd={!hidePreview ? 1.5 : 0}
            paddingTop='1px'
          >
            {group.name}
          </chakra.span>
          {!hidePreview && (
            <Tooltip
              colorScheme='russianViolet'
              hasArrow
              label='Open Group preview'
              placement='top-end'
              offset={[6, 10]}
            >
              <IconButton
                _active={{
                  backgroundColor: 'transparent',
                }}
                _hover={{
                  backgroundColor: 'transparent',
                }}
                aria-label='role details'
                icon={<Icon as={BiWindows} color='iris.500' boxSize='14px' />}
                height='fit-content'
                minWidth='fit-content'
                onClick={(event) => {
                  event.stopPropagation()
                  dispatch(toggleGroupDialog(group))
                }}
                padding={0}
                variant='ghost'
              />
            </Tooltip>
          )}
        </HStack>
      </TagLabel>
    </Tag>
  )
}

export default GroupTag
