import { useRef } from 'react'
import {
  Flex,
  Img,
  Link,
  Text,
  useDimensions,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import NotFound404 from '@/assets/images/NotFound404.png'

const Page404 = () => {
  const elementRef = useRef(null)
  const elementDimensions = useDimensions(elementRef)
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='80vh'
    >
      <Wrap justify='center' spacingX='5rem' spacingY='2.5rem'>
        <WrapItem width={elementDimensions?.borderBox?.width}>
          <Img src={NotFound404} />
        </WrapItem>
        <WrapItem ref={elementRef} alignItems='center'>
          <VStack alignItems='flex-start' spacing={3}>
            <Text
              color='russianViolet.500'
              fontWeight='bold'
              fontSize='7.5rem'
              lineHeight='7.5rem'
            >
              404
            </Text>
            <VStack
              fontWeight='semibold'
              color='gray.500'
              spacing={1}
              alignItems='flex-start'
            >
              <Text fontSize='md'>
                Sorry, the page you are looking for is lost in space...
              </Text>
              <Text fontSize='md'>
                Head back to our{' '}
                <Link
                  as={RouterLink}
                  to='/'
                  color='iris.500'
                  textDecoration='underline'
                >
                  dashboard
                </Link>
                .
              </Text>
            </VStack>
          </VStack>
        </WrapItem>
      </Wrap>
    </Flex>
  )
}

export default Page404
