import { useState, useEffect, useRef } from 'react'

const useThrottle = (callback: (...args: any) => void, delay: number) => {
  const [isThrottled, setIsThrottled] = useState(false)
  const timerRef = useRef<number | undefined>(undefined)

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  const throttledCallback = (...args: any) => {
    if (!isThrottled) {
      callback(...args)
      setIsThrottled(true)
      timerRef.current = window.setTimeout(() => {
        setIsThrottled(false)
      }, delay)
    }
  }

  return throttledCallback
}

export default useThrottle
