import * as React from 'react'
import { Components } from 'react-markdown'
import {
  chakra,
  Checkbox,
  Code,
  Divider,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react'

type GetCoreProps = {
  children?: React.ReactNode
  'data-sourcepos'?: any
}

function getCoreProps(props: GetCoreProps): any {
  return props['data-sourcepos']
    ? { 'data-sourcepos': props['data-sourcepos'] }
    : {}
}

interface Defaults extends Components {
  heading?: Components['h1']
}

export const defaults: Defaults = {
  a: (props) => <Link {...props} color='iris.500' textDecoration='underline' />,
  p: (props) => {
    const { children } = props
    return <Text marginBottom={2}>{children}</Text>
  },
  em: (props) => {
    const { children } = props
    return <Text as='em'>{children}</Text>
  },
  blockquote: (props) => {
    const { children } = props
    return (
      <Code as='blockquote' padding={2}>
        {children}
      </Code>
    )
  },
  code: (props) => {
    const { inline, children, className } = props

    if (inline) {
      return <Code padding={2} children={children} />
    }

    return (
      <Code
        className={className}
        whiteSpace='break-spaces'
        display='block'
        width='full'
        padding={2}
        children={children}
      />
    )
  },
  del: (props) => {
    const { children } = props
    return <Text as='del'>{children}</Text>
  },
  hr: () => <Divider />,
  img: Image,
  text: (props) => {
    const { children } = props
    return <Text as='span'>{children}</Text>
  },
  ul: (props) => {
    const { ordered, children, depth } = props
    const attrs = getCoreProps(props)
    let Element = UnorderedList
    let styleType = 'disc'
    if (ordered) {
      Element = OrderedList
      styleType = 'decimal'
    }
    if (depth === 1) styleType = 'circle'
    return (
      <Element
        spacing={0}
        as={ordered ? 'ol' : 'ul'}
        styleType={styleType}
        paddingLeft={4}
        {...attrs}
      >
        {children}
      </Element>
    )
  },
  ol: (props) => {
    const { ordered, children, depth } = props
    const attrs = getCoreProps(props)
    let Element = UnorderedList
    let styleType = 'disc'
    if (ordered) {
      Element = OrderedList
      styleType = 'decimal'
    }
    if (depth === 1) styleType = 'circle'
    return (
      <Element
        spacing={0}
        as={ordered ? 'ol' : 'ul'}
        styleType={styleType}
        paddingLeft={4}
        {...attrs}
      >
        {children}
      </Element>
    )
  },
  li: (props) => {
    const { children, checked } = props
    let checkbox = null
    if (checked !== null && checked !== undefined) {
      checkbox = (
        <Checkbox isChecked={checked} isReadOnly>
          {children}
        </Checkbox>
      )
    }
    return (
      <ListItem
        {...getCoreProps(props)}
        listStyleType={checked !== null ? 'none' : 'inherit'}
      >
        {checkbox || (
          <chakra.span color='gray.700' fontSize='sm'>
            {children}
          </chakra.span>
        )}
      </ListItem>
    )
  },
  heading: (props) => {
    const { level, children } = props
    const sizes = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs']
    return (
      <Heading
        as={`h${level}`}
        fontWeight='semibold'
        marginY={4}
        size={sizes[level - 1]}
        {...getCoreProps(props)}
      >
        {children}
      </Heading>
    )
  },
  pre: (props) => {
    const { children } = props
    return <chakra.pre {...getCoreProps(props)}>{children}</chakra.pre>
  },
  table: Table,
  thead: Thead,
  tbody: Tbody,
  tr: (props) => <Tr>{props.children}</Tr>,
  td: (props) => <Td>{props.children}</Td>,
  th: (props) => <Th>{props.children}</Th>,
}

const ChakraMarkDownOverrides = {
  a: defaults.a,
  blockquote: defaults.blockquote,
  code: defaults.code,
  del: defaults.del,
  em: defaults.em,
  h1: defaults.heading,
  h2: defaults.heading,
  h3: defaults.heading,
  h4: defaults.heading,
  h5: defaults.heading,
  h6: defaults.heading,
  hr: defaults.hr,
  img: defaults.img,
  li: defaults.li,
  ol: defaults.ol,
  p: defaults.p,
  pre: defaults.pre,
  table: defaults.table,
  tbody: defaults.tbody,
  td: defaults.td,
  text: defaults.text,
  th: defaults.th,
  thead: defaults.thead,
  tr: defaults.tr,
  ul: defaults.ul,
}

export default ChakraMarkDownOverrides
