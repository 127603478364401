import { FieldBaseProps } from '@/commons/types'
import {
  FormLabel,
  HStack,
  Popover,
  PopoverTrigger,
  Flex,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Text,
} from '@chakra-ui/react'
import { BiHelpCircle } from 'react-icons/bi'

export interface FieldLabelProps extends FieldBaseProps {
  hasError?: boolean
}

const FieldLabel = ({
  hasError,
  label,
  LabelComponent,
  labelProps,
  name,
  subLabel,
  tooltipMessage,
}: FieldLabelProps) => (
  <FormLabel
    color={hasError ? 'red.500' : 'gray.700'}
    htmlFor={name}
    fontWeight='semibold'
    fontSize='sm'
    textTransform='capitalize'
    marginBottom={1}
    {...labelProps}
  >
    {LabelComponent ? (
      LabelComponent
    ) : (
      <HStack lineHeight={6} spacing={1}>
        <Text>{label}</Text>
        {tooltipMessage && (
          <Popover
            offset={[-10, 10]}
            isLazy
            trigger='hover'
            placement='top-start'
            variant='info'
          >
            <PopoverTrigger>
              <Flex>
                <Icon as={BiHelpCircle} boxSize='12px' color='iris.500' />
              </Flex>
            </PopoverTrigger>
            <PopoverContent borderWidth={0} width='fit-content' maxWidth='30vw'>
              <PopoverArrow backgroundColor='russianViolet.600' />
              <PopoverBody
                fontWeight='normal'
                color='white'
                fontSize='xs'
                borderRadius='8px'
              >
                {tooltipMessage}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {subLabel && (
          <Text
            color='gray.500'
            fontSize='xs'
            fontWeight='normal'
            textTransform='none'
          >
            {subLabel}
          </Text>
        )}
      </HStack>
    )}
  </FormLabel>
)

export default FieldLabel
