import { useCallback } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { BiUpload } from 'react-icons/bi'

import { FormControl, Center, Icon, VStack } from '@chakra-ui/react'
import { FieldHelperProps, useField } from 'formik'
import { uniqBy } from 'lodash'
import { FieldBaseProps } from '@/commons/types'
import { FieldLabel } from '@/components/atoms'

// use prop isDisabled from FieldBaseProps
type ModifiedDropzoneOptions = Omit<DropzoneOptions, 'disabled' | 'onDrop'>
type CustomDropzoneProps = {
  DropzoneMessageElement: React.ReactNode
  onDrop?: (
    acceptedFiles: File[],
    fieldHelpers: FieldHelperProps<File[]>
  ) => void
}

export type FieldDropzoneProps = FieldBaseProps &
  ModifiedDropzoneOptions &
  CustomDropzoneProps

const FieldDropzone = ({
  accept,
  DropzoneMessageElement,
  hideErrorMessage,
  isDisabled,
  label,
  LabelComponent,
  maxFiles,
  name,
  onDrop,
  labelProps,
  subLabel,
  tooltipMessage,
  ...dropProps
}: FieldDropzoneProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, fieldHelpers] = useField<File[]>(name)
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onDrop) onDrop(acceptedFiles, fieldHelpers)
      else {
        const uniqFiles = uniqBy(field.value.concat(acceptedFiles), 'name')
        const filteredFilesListByMaxFiles = maxFiles
          ? uniqFiles.slice(-1 * maxFiles)
          : uniqFiles

        fieldHelpers.setValue(filteredFilesListByMaxFiles)
      }
    },
    [fieldHelpers, field.value, onDrop, maxFiles]
  )

  const { getRootProps, getInputProps, isDragReject, isDragActive } =
    useDropzone({
      onDrop: handleDrop,
      accept,
      maxFiles,
      ...dropProps,
    })

  const backgroundColor = isDragReject
    ? 'red.50'
    : isDragActive
    ? 'iris.100'
    : 'iris.50'

  return (
    <FormControl>
      {label && (
        <FieldLabel
          label={label}
          LabelComponent={LabelComponent}
          labelProps={labelProps}
          name={name}
          subLabel={subLabel}
          tooltipMessage={tooltipMessage}
        />
      )}

      <Center
        cursor='pointer'
        border='1px dashed'
        borderColor={isDragReject ? 'red.500' : 'iris.500'}
        backgroundColor={backgroundColor}
        borderRadius='lg'
        padding={5}
        {...getRootProps()}
      >
        <VStack>
          <Center borderRadius='full' backgroundColor='iris.100' padding={3}>
            <Icon as={BiUpload} boxSize={6} color='iris.500' />
          </Center>
          {DropzoneMessageElement}
          <input {...getInputProps()} />
        </VStack>
      </Center>
    </FormControl>
  )
}

export default FieldDropzone
