import {
  cleanBreadcrumbDetails,
  setBreadCrumbDetails,
} from '@/store/breadCrumbDetailsSlice'
import { useEffect } from 'react'
import useAppDispatch from './useAppDispatch'

type UseBreadCrumb = {
  isError: boolean
  isLoading: boolean
  name?: string
}

const useBreadCrumb = ({ isError, isLoading, name }: UseBreadCrumb) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadCrumbDetails({ isError, isLoading, name }))
  }, [isError, isLoading, name, dispatch])

  useEffect(() => {
    return () => {
      dispatch(cleanBreadcrumbDetails())
    }
  }, [dispatch])
}

export default useBreadCrumb
