import {
  Box,
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import {
  BiEnvelope,
  BiGridAlt,
  BiGroup,
  BiLockAlt,
  BiLogOutCircle,
  BiSitemap,
} from 'react-icons/bi'
import { BiChevronDown } from 'react-icons/bi'

import { useAppDispatch, useAppSelector, useKeycloak } from '@/hooks'
import { logout, selectOrganizationDetails } from '@/store/guardianSlice'

import AvatarWithInitials from '@/components/atoms/AvatarWithInitials'
import MenuSectionHeader from '@/components/atoms/MenuSectionHeader'
import MenuItemLink from '@/components/atoms/MenuItemLink'

type HeaderProps = {
  isHomePage: boolean
}

const Header = ({ isHomePage }: HeaderProps) => {
  const { isUserAdmin, user } = useKeycloak()
  const dispatch = useAppDispatch()
  const orgDetails = useAppSelector(selectOrganizationDetails)
  const tokenParsed = user?.profile
  const name = tokenParsed?.name
  const email = tokenParsed?.email

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Menu gutter={4} autoSelect={false}>
      <MenuButton transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
        <HStack spacing={0}>
          <AvatarWithInitials
            src={orgDetails?.attributes?.logo}
            name={name}
            size='md'
          />
          <Box display='flex'>
            <Icon
              as={BiChevronDown}
              color={isHomePage ? 'white' : 'iris.500'}
              boxSize={5}
            />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList
        bg='white'
        paddingTop={4}
        paddingBottom={0}
        minWidth='280px'
        boxShadow='md'
        borderRadius='8px'
      >
        {/* User section */}
        <Box paddingX={4} paddingBottom={2}>
          <MenuSectionHeader
            sectionTitle={name}
            sectionSubtitle={email}
            fallbackName={name}
          />
          <MenuItem
            _active={{
              backgroundColor: 'transparent',
              fontWeight: 'semibold',
              color: 'gray.600',
            }}
            _hover={{
              backgroundColor: 'gray.100',
            }}
            onClick={handleLogout}
            color='iris.500'
            fontSize='sm'
            borderRadius='8px'
          >
            <HStack spacing={3.5}>
              <Icon as={BiLogOutCircle} boxSize='18px' />
              <chakra.span>Sign out</chakra.span>
            </HStack>
          </MenuItem>
        </Box>

        {/* Admin section */}
        {isUserAdmin && !!orgDetails && (
          <Box>
            <MenuDivider margin={0} />
            <Box
              backgroundColor='gray.50'
              paddingX={4}
              paddingTop={4}
              paddingBottom={4}
              borderBottomRadius='8px'
            >
              <MenuSectionHeader
                src={orgDetails.attributes?.logo}
                sectionTitle={orgDetails.display_name}
                fallbackName={orgDetails.display_name}
              />
              <MenuItemLink
                to='/admin/org-settings'
                icon={BiGridAlt}
                roles={['admin:organizations:view']}
              >
                Organization Settings
              </MenuItemLink>
              <MenuItemLink
                to='/admin/users'
                icon={BiGroup}
                roles={['admin:users:view']}
              >
                Users
              </MenuItemLink>
              <MenuItemLink to='/admin/invites' icon={BiEnvelope}>
                Invites
              </MenuItemLink>
              <MenuItemLink
                to='/admin/groups'
                icon={BiSitemap}
                roles={['admin:groups:view']}
              >
                Groups
              </MenuItemLink>
              <MenuItemLink
                to='/admin/roles'
                icon={BiLockAlt}
                roles={['admin:roles:view']}
              >
                Roles
              </MenuItemLink>
            </Box>
          </Box>
        )}
      </MenuList>
    </Menu>
  )
}

export default Header
