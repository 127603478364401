import { Flex, Box, VStack, Icon, Text } from '@chakra-ui/react'
import { BiSad } from 'react-icons/bi'

const TableNoResults = () => (
  <Flex justify='center' alignItems='center' h='40vh'>
    <VStack>
      <Icon as={BiSad} color='fluorescentBlue.500' boxSize='40px' />
      <Box textAlign='center'>
        <Text fontSize='lg' fontWeight='semibold' color='gray.600'>
          No results found.
        </Text>
        <Text fontSize='xs' color='gray.500'>
          Try adjusting your search or filter to find what you're looking for.
        </Text>
      </Box>
    </VStack>
  </Flex>
)

export default TableNoResults
