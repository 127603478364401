import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { reactAppEnv } from '@/commons/helpers/LocalEnvData'
import breadCrumbDetailsSlice from './breadCrumbDetailsSlice'
import guardianReducer from './guardianSlice'
import sidebarSlice from './sidebarSlice'
import sideModalControllerSlice from './sideModalControllerSlice'

const middlewares = []

if (reactAppEnv !== 'production') {
  middlewares.push(logger)
}

export const store = configureStore({
  reducer: {
    guardian: guardianReducer,
    sidebarState: sidebarSlice,
    sideModalController: sideModalControllerSlice,
    breadCrumbDetailsState: breadCrumbDetailsSlice,
  },
  middleware: middlewares,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
