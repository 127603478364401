const Button = {
  baseStyle: {
    borderRadius: '6px',
  },
  variants: {
    danger: {
      _active: {
        backgroundColor: 'red.800',
      },
      _hover: {
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        backgroundColor: 'red.700',
      },
      color: 'white',
      backgroundColor: 'red.600',
    },
    'danger-ghost': {
      _active: {
        backgroundColor: 'gray.200',
        color: 'iris.800',
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      _hover: {
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        backgroundColor: 'gray.100',
        color: 'red.700',
      },
      color: 'red.600',
    },
    'danger-outline': {
      _active: {
        backgroundColor: 'gray.200',
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      _hover: {
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        backgroundColor: 'gray.100',
      },
      borderColor: 'red.600',
      borderWidth: '1px',
      color: 'red.600',
    },
    ghost: {
      _active: {
        backgroundColor: 'gray.200',
        color: 'iris.800',
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      _hover: {
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        backgroundColor: 'gray.100',
        color: 'iris.700',
      },
      color: 'iris.500',
    },
    'icon-button-ghost': {
      _active: {
        color: 'iris.800',
      },
      _hover: {
        color: 'iris.700',
      },
      color: 'iris.500',
      height: 5,
      minWidth: 'fit-content',
      padding: 0,
    },
    'menu-button': {
      _hover: {
        borderColor: 'gray.300',
      },
      borderWidth: '1px',
      backgroundColor: 'white',
      color: 'gray.700',
      borderColor: 'gray.200',
    },
    'modal-close-button': {
      _active: {
        color: 'russianViolet.800',
      },
      _hover: {
        color: 'russianViolet.700',
        backGroundColor: 'transparent',
      },
      color: 'russianViolet.600',
      minWidth: 'fit-content',
      padding: 0,
      height: 'fit-content',
    },
    outline: {
      _active: {
        backgroundColor: 'gray.200',
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      _hover: {
        backgroundColor: 'gray.100',
      },
      borderColor: 'iris.500',
      color: 'iris.500',
    },
    solid: {
      _active: {
        backgroundColor: 'iris.800',
      },
      _disabled: {
        bgColor: 'iris.400',
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      _hover: {
        _disabled: {
          bgColor: 'iris.400',
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        backgroundColor: 'iris.700',
      },
      backgroundColor: 'iris.500',
      color: 'white',
    },
  },
  sizes: {
    md: {
      fontSize: 'sm',
    },
  },
}

export default Button
