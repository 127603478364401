import {
  Menu,
  MenuButton,
  Button,
  Icon,
  HStack,
  chakra,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { BiChevronDown, BiCheck } from 'react-icons/bi'

type TableSelectorProps = {
  selectorName: string
  selectorValue: string
  onChange: (value: string) => void
  selectorOptions: string[]
}

const TableSelector = ({
  selectorName,
  selectorValue,
  selectorOptions,
  onChange,
}: TableSelectorProps) => (
  <Menu gutter={1} matchWidth>
    <MenuButton
      as={Button}
      rightIcon={<Icon as={BiChevronDown} boxSize='22px' color='iris.500' />}
      variant='menu-button'
      minWidth='fit-content'
      flexGrow={1}
      flexShrink={0}
    >
      <HStack fontSize='sm' justifyContent='flex-start'>
        <chakra.span fontWeight='normal'>{selectorName}:</chakra.span>
        <chakra.span fontWeight='semibold'>{selectorValue}</chakra.span>
      </HStack>
    </MenuButton>
    <MenuList boxShadow='md' borderRadius='8px'>
      {selectorOptions.map((option: string) => (
        <MenuItem
          _hover={{ backgroundColor: 'gray.100' }}
          color='iris.500'
          fontSize='sm'
          fontWeight={option === selectorValue ? '700' : '400'}
          key={option}
          onClick={() => onChange(option)}
          value={option}
        >
          <HStack width='100%'>
            <chakra.span width='95%'>{option}</chakra.span>
            {option === selectorValue && (
              <Icon as={BiCheck} boxSize={5} color='iris.500' />
            )}
          </HStack>
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
)

export default TableSelector
