/* 
  This service is used to query the GraphQL APIs.
  It takes a generic type of the entity name, the entity type and the fields that are provided.
  It returns a function that takes the variables and the query fields and returns a promise of the data.
  The query fields are typed recursively.
  Refs:
  - https://github.com/vkolgi/json-to-graphql-query
  - https://graphql.org/learn/pagination/#pagination-and-edges
  - https://graphql.org/learn/global-object-identification/#node-interface

*/

import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'
import Axios from './Axios'
import { GraphQLQueryVariables, GraphQLQueryResponse } from '../types'

type QueryService<
  EntityName extends string,
  EntityType,
  ProvidedFields extends keyof EntityType
> = {
  entityName: EntityName
  route: string
  variables: GraphQLQueryVariables<Partial<EntityType>>
  // todo find a way to type recursively
  queryFields: { [k in keyof Pick<EntityType, ProvidedFields>]: any }
  orderingVariableType?: string
  omitTotal?: boolean
  omitSubfilters?: boolean
}

const makeVariables = (
  omitSubfilters: boolean,
  orderingVariableType?: string
) => {
  let variables = {
    after: 'Int!',
    first: 'Int!',
    filters: 'JSONScalar',
    subfilters: 'JSONScalar',
  } as Record<string, string>
  if (orderingVariableType) {
    variables.ordering = orderingVariableType
  }
  if (omitSubfilters) delete variables.subfilters
  return variables
}

const makeArgs = (omitSubfilters: boolean, orderingVariableType?: string) => {
  let args = {
    after: new VariableType('after'),
    first: new VariableType('first'),
    filters: new VariableType('filters'),
    subfilters: new VariableType('subfilters'),
  } as Record<string, VariableType>
  if (orderingVariableType) {
    args.ordering = new VariableType('ordering')
  }
  if (omitSubfilters) delete args.subfilters
  return args
}

// todo support field aliases
const queryService = <
  EntityName extends string,
  EntityType,
  ProvidedFields extends keyof EntityType = keyof EntityType
>({
  entityName,
  queryFields,
  route,
  variables,
  orderingVariableType,
  omitTotal,
  omitSubfilters,
}: QueryService<EntityName, EntityType, ProvidedFields>) =>
  Axios.post<GraphQLQueryResponse<EntityName, EntityType, ProvidedFields>>(
    route,
    {
      query: jsonToGraphQLQuery({
        query: {
          __variables: makeVariables(!!omitSubfilters, orderingVariableType),
          [entityName]: {
            __args: makeArgs(!!omitSubfilters, orderingVariableType),
            total: !omitTotal,
            edges: { node: queryFields },
          },
        },
      }),
      variables,
    }
  )
    .then((response) => {
      if (response.data.data?.[entityName]) {
        return {
          data:
            // here entityName is used as a key to access the data from the graphql pagination response
            response.data.data?.[entityName].edges?.map((edge) => edge.node) ??
            [],
          total: response.data.data[entityName].total,
        }
      }
    })
    .catch((errors) => console.error(errors))

export default queryService
