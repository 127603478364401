import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export type OrganizationDetails = {
  id: string
  name: string
  display_name: string
  url: string
  domains: string[]
  attributes: {
    created_at: string
    logo: string
  }
}

export interface GuardianState {
  organizationDetails: OrganizationDetails | null
  invalidToken: boolean
}

const initialState: GuardianState = {
  organizationDetails: null,
  invalidToken: false,
}

export const guardianSlice = createSlice({
  name: 'guardian',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOrganizationDetails: (state, action) => {
      const { payload } = action
      state.organizationDetails = payload
    },
    logout: (state) => {
      state.invalidToken = true
    },
  },
})

export const { setOrganizationDetails, logout } = guardianSlice.actions

export const selectOrganizationDetails = ({ guardian }: RootState) =>
  guardian.organizationDetails

export const selectIsInvalidToken = ({ guardian }: RootState) =>
  guardian.invalidToken

export default guardianSlice.reducer
