import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
} from '@chakra-ui/react'

type CustomTab = {
  tabLabel: string
  tabPanelComponent: () => JSX.Element
  shouldRenderTab: () => boolean
  [key: string]: any
}

type CustomTabsProps = {
  tabIndex: number
  onTabChange: (index: number) => void
  tabList: CustomTab[]
}

const CustomTabs = ({ tabIndex, onTabChange, tabList }: CustomTabsProps) => (
  <Tabs height='100%' index={tabIndex} isLazy onChange={onTabChange}>
    <TabList
      paddingLeft={5}
      backgroundColor='tabsBackground'
      height='3.25rem'
      borderWidth={0}
      boxShadow='inset 0px -1px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(0, 0, 0, 0.05)'
    >
      {tabList.map((tab, index) =>
        tab.shouldRenderTab() ? (
          <Box position='relative' key={index}>
            <Flex height='100%' alignItems='center'>
              <Tab
                _active={{ backgroundColor: 'transparent' }}
                _focus={{ outline: false }}
                _selected={{ color: 'iris.500' }}
                borderWidth={0}
                color='iris.500'
                fontSize='sm'
                fontWeight='semibold'
                marginBottom={1}
                paddingTop={3}
                paddingBottom={2}
                paddingX={4}
              >
                {tab.tabLabel}
              </Tab>
            </Flex>
            {tabIndex === index && (
              <Box
                position='absolute'
                width='100%'
                height='4px'
                borderRadius='4px 4px 0px 0px'
                backgroundColor='fluorescentBlue.500'
                bottom='0'
              />
            )}
          </Box>
        ) : null
      )}
    </TabList>

    <TabPanels height='100%'>
      {tabList.map((tab, index) =>
        tab.shouldRenderTab() ? (
          <TabPanel paddingX={0} paddingY={5} key={index}>
            <Box paddingX={5}>{tab.tabPanelComponent()}</Box>
          </TabPanel>
        ) : null
      )}
    </TabPanels>
  </Tabs>
)

export default CustomTabs
