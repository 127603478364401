import { AuthProvider, AuthProviderProps } from 'react-oidc-context'
import { Provider } from 'react-redux'

import { UserManagerClient } from '@/commons/helpers'
import Routes from '@/components/molecules/Routes'
import { store } from '@/store/store'

const oidcConfig: AuthProviderProps = {
  userManager: UserManagerClient,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

const Guardian = () => (
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </AuthProvider>
)

export default Guardian
