import React from 'react'
import Select, { GroupBase } from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { Button, Flex, FormErrorMessage, HStack } from '@chakra-ui/react'

import { SelectOption } from '@/commons/types'

import makeStyles from './makeStyles'
import DropdownIndicator from '../DropdownIndicator'
import OptionComponent from '../Option'
import { FieldCreatableSelectProps } from '../..'
import { BiPlusCircle } from 'react-icons/bi'

interface OptionsSelectorProps
  extends Pick<
    FieldCreatableSelectProps,
    | 'hideErrorMessage'
    | 'isDisabled'
    | 'isLoading'
    | 'name'
    | 'noOptionsMessage'
    | 'placeholder'
  > {
  addNewLabel: string
  components?:
    | Partial<SelectComponents<any, boolean, GroupBase<any>>>
    | undefined
  errorMessage?: string
  hasError?: boolean
  isFocused?: boolean
  onBlur: () => void
  onChangeOptionSelect: (value: SelectOption) => void
  onClickCreateNew: () => void
  onFocus: () => void
  options: SelectOption[]
  value: SelectOption | undefined
}

const OptionsSelector = ({
  addNewLabel,
  components,
  errorMessage,
  hasError,
  hideErrorMessage,
  isDisabled,
  isFocused,
  isLoading,
  name,
  noOptionsMessage,
  onBlur,
  onChangeOptionSelect,
  onClickCreateNew,
  onFocus,
  options,
  placeholder,
  value,
}: OptionsSelectorProps) => (
  <>
    <HStack
      _hover={{
        borderColor: isFocused || isDisabled ? 'none' : 'gray.300',
      }}
      borderColor={
        isFocused
          ? 'iris.500'
          : !isDisabled && hasError
          ? 'red.500'
          : 'gray.200'
      }
      borderWidth='1px'
      borderRadius='8px'
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      spacing={0}
      width='100%'
    >
      <Select
        components={{
          DropdownIndicator,
          Option: OptionComponent,
          ...components,
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        menuPlacement='auto'
        menuPortalTarget={document.body}
        minMenuHeight={300}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={onChangeOptionSelect}
        onFocus={onFocus}
        options={options}
        placeholder={placeholder}
        styles={makeStyles()}
        value={value}
      />
    </HStack>

    {!hideErrorMessage && (
      <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
        {errorMessage}
      </FormErrorMessage>
    )}

    <Flex width='100%' justifyContent='flex-start' marginTop={2}>
      <Button
        leftIcon={<BiPlusCircle />}
        variant='ghost'
        fontSize='sm'
        onClick={onClickCreateNew}
      >
        {addNewLabel}
      </Button>
    </Flex>
  </>
)

export default OptionsSelector
