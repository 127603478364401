import { Box, chakra, HStack, Icon } from '@chakra-ui/react'
import { HiCheckCircle } from 'react-icons/hi'

type SuccessToastProps = {
  title?: string
}

const SuccessToast = ({ title }: SuccessToastProps) => (
  <Box
    backgroundColor='green.100'
    boxShadow=' base'
    borderRadius='6px'
    paddingX={4}
    paddingY={3}
  >
    <HStack spacing={3}>
      <Icon as={HiCheckCircle} boxSize='18.5px' color='green.500' />
      <chakra.span
        color='gray.700'
        fontSize='sm'
        fontWeight='bold'
        lineHeight='24px'
      >
        {title}
      </chakra.span>
    </HStack>
  </Box>
)

export default SuccessToast
