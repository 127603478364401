import { IconType } from 'react-icons'
import {
  BiServer,
  BiTask,
  BiAddToQueue,
  BiHeadphone,
  BiData,
  BiLibrary,
} from 'react-icons/bi'
import { MdDashboard } from 'react-icons/md'

type SectionItemProps = {
  linkItems?: { name: string; route: string }[]
  name: string
  rootUrl?: string
  route?: string
  SectionIcon: IconType
  subSection: string
  externalUrl?: string
}

type DashboardItemProps = {
  SectionIcon: IconType
  name: string
  route: string
  subSection: string
}

const DashboardItems: DashboardItemProps[] = [
  {
    name: 'Home',
    SectionIcon: MdDashboard,
    route: '/',
    subSection: 'dashboards',
  },
]

const SectionItems: SectionItemProps[] = [
  {
    name: 'Clusters',
    route: '/cluster',
    SectionIcon: BiServer,
    subSection: 'apps',
  },
  {
    name: 'Storage',
    route: '/storage',
    SectionIcon: BiData,
    subSection: 'apps',
  },
  {
    name: 'Licenses',
    rootUrl: '/lm',
    linkItems: [
      {
        name: 'License Configurations',
        route: '/lm/configurations',
      },
      {
        name: 'Inventory',
        route: '/lm/inventory',
      },
    ],
    SectionIcon: BiTask,
    subSection: 'apps',
  },
  {
    name: 'Jobs',
    rootUrl: '/jobs',
    linkItems: [
      {
        name: 'Job Script Templates',
        route: '/jobs/job-script-templates',
      },
      {
        name: 'Job Scripts',
        route: '/jobs/job-scripts',
      },
      {
        name: 'Job Submissions',
        route: '/jobs/job-submissions',
      },
    ],
    SectionIcon: BiAddToQueue,
    subSection: 'apps',
  },
  {
    name: 'Support',
    route: '/support',
    SectionIcon: BiHeadphone,
    subSection: 'helpdesk',
  },
  {
    name: 'Vantage Docs',
    SectionIcon: BiLibrary,
    subSection: 'helpdesk',
    externalUrl: 'https://docs.vantagehpc.io/',
  },
]

export { DashboardItems, SectionItems }
