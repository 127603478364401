import React, { useEffect, useState } from 'react'
import { GroupBase } from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { FormControl } from '@chakra-ui/react'
import { useField } from 'formik'
import { useMutation } from 'react-query'

import { FieldBaseProps, SelectOption } from '@/commons/types'
import { FieldLabel } from '@/components/atoms'

import OptionsSelector from './components/OptionsSelector'
import CreateOptionInput from './components/CreateOptionInput'

export interface FieldCreatableSelectProps extends FieldBaseProps {
  addNewLabel: string
  components?:
    | Partial<SelectComponents<any, boolean, GroupBase<any>>>
    | undefined
  createNewOptionLabel: string
  createService: (value: string) => Promise<SelectOption>
  initialOptions: SelectOption[]
  isLoading?: boolean
  isMulti?: boolean
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode
  refetch: () => void
}

type FieldCreatableSelectErrors = {
  label?: string
  value?: string
}

const COMPONENT_BEHAVIOR_SELECT_OPTION = 0,
  COMPONENT_BEHAVIOR_CREATE_NEW = 1

const FieldCreatableSelect = ({
  addNewLabel,
  components,
  createNewOptionLabel,
  createService,
  externalError,
  hideErrorMessage,
  initialOptions,
  isDisabled,
  isLoading,
  isRequired,
  label,
  LabelComponent,
  labelProps,
  name,
  noOptionsMessage,
  placeholder,
  refetch,
  subLabel,
  tooltipMessage,
}: FieldCreatableSelectProps) => {
  const [options, setOptions] = useState(initialOptions)
  const [isFocused, setIsFocused] = useState(false)
  const [createInputValue, setCreateInputValue] = useState('')
  const [componentBehavior, setComponentBehavior] = useState(
    COMPONENT_BEHAVIOR_SELECT_OPTION
  )
  const [field, meta, helpers] = useField<SelectOption>(name)

  const {
    mutate,
    isLoading: isCreatingNewOption,
    error: createError,
  } = useMutation<SelectOption, Error>(() => createService(createInputValue), {
    onSuccess: (data) => {
      helpers.setValue(data)
      toggleComponentBehavior()
      refetch()
    },
  })

  useEffect(() => {
    setOptions(initialOptions)
  }, [initialOptions])

  const metaError = meta.error as FieldCreatableSelectErrors
  const errorMessage = metaError?.label || metaError?.value || externalError
  const hasError = !!(errorMessage && meta.touched)

  const fieldValue = options.find(
    ({ value }) => String(value) === String(field.value.value)
  )

  const handleChangeOptionSelect = (selectedOption: SelectOption): void => {
    helpers.setValue(selectedOption)
  }

  const handleChangeCreateInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCreateInputValue(event.target.value)
  }

  const handleBlur = (): void => {
    helpers.setTouched(true)
    setIsFocused(false)
  }
  const handleFocus = () => setIsFocused(true)

  const toggleComponentBehavior = () => {
    setCreateInputValue('')
    setComponentBehavior(
      componentBehavior === COMPONENT_BEHAVIOR_SELECT_OPTION
        ? COMPONENT_BEHAVIOR_CREATE_NEW
        : COMPONENT_BEHAVIOR_SELECT_OPTION
    )
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={hasError}>
      {label && (
        <FieldLabel
          hasError={hasError}
          label={label}
          LabelComponent={LabelComponent}
          labelProps={labelProps}
          name={name}
          subLabel={subLabel}
          tooltipMessage={tooltipMessage}
        />
      )}
      {componentBehavior === COMPONENT_BEHAVIOR_SELECT_OPTION && (
        <OptionsSelector
          addNewLabel={addNewLabel}
          components={components}
          errorMessage={errorMessage}
          hasError={hasError}
          hideErrorMessage={hideErrorMessage}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          noOptionsMessage={noOptionsMessage}
          onBlur={handleBlur}
          onChangeOptionSelect={handleChangeOptionSelect}
          onClickCreateNew={toggleComponentBehavior}
          onFocus={handleFocus}
          options={options}
          placeholder={placeholder}
          value={fieldValue}
        />
      )}

      {componentBehavior === COMPONENT_BEHAVIOR_CREATE_NEW && (
        <CreateOptionInput
          createNewOptionLabel={createNewOptionLabel}
          createNewOptionService={mutate}
          error={createError}
          inputValue={createInputValue}
          isCreatingNewOption={isCreatingNewOption}
          name={name}
          onCancelCreateOption={toggleComponentBehavior}
          onChangeCreateInput={handleChangeCreateInput}
        />
      )}
    </FormControl>
  )
}

export default FieldCreatableSelect
