import { useCallback } from 'react'
import { BiBell, BiCool } from 'react-icons/bi'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react'

import { AsyncActionButton, NotificationMenuItem } from '@/components/atoms'
import { checkAllAlerts } from './services'
import useNotifications from './useNotifications'
import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'

type NotificationMenuProps = {
  isHomePage: boolean
}

const NotificationMenu = ({ isHomePage }: NotificationMenuProps) => {
  const { alerts, uncheckedAlertsQtd, refetchNotifications } =
    useNotifications()

  const handleClearAll = useCallback(async () => {
    await checkAllAlerts()
    refetchNotifications()
  }, [refetchNotifications])

  return (
    <Menu isLazy>
      <MenuButton
        _active={{
          backgroundColor: isHomePage
            ? getColorWithOpacity('gray.200', 0.2)
            : 'gray.200',
        }}
        _hover={{
          backgroundColor: isHomePage
            ? getColorWithOpacity('gray.100', 0.2)
            : 'gray.100',
        }}
        as={Button}
        padding={1}
        variant='ghost'
      >
        <HStack justify='center' spacing={1}>
          <Icon
            as={BiBell}
            boxSize={5}
            color={isHomePage ? 'white' : 'iris.500'}
          />
          {uncheckedAlertsQtd > 0 && (
            <Tag
              visibility='visible'
              backgroundColor='pink.500'
              borderRadius='full'
              color='white'
              size='sm'
              fontWeight='semibold'
            >
              {uncheckedAlertsQtd > 10 ? '10+' : uncheckedAlertsQtd}
            </Tag>
          )}
        </HStack>
      </MenuButton>
      <MenuList
        maxHeight='400px'
        overflowX='hidden'
        overflowY='auto'
        width='340px'
      >
        {/* no notifications */}
        {alerts.length === 0 && (
          <Center height='260px'>
            <VStack spacing={3}>
              <Icon as={BiCool} color='fluorescentBlue.500' boxSize={8} />
              <VStack spacing={1}>
                <Text fontWeight='semibold' fontSize='lg' color='gray.600'>
                  Sweet!
                </Text>
                <Text color='gray.500' fontSize='xs'>
                  No notifications to be shown.
                </Text>
              </VStack>
            </VStack>
          </Center>
        )}
        {alerts.length > 0 && (
          <Box>
            <Flex
              width='100%'
              justify='flex-end'
              paddingRight={1}
              paddingBottom={1}
            >
              {uncheckedAlertsQtd > 0 && (
                <AsyncActionButton
                  onClickPromise={handleClearAll}
                  variant='ghost'
                  size='sm'
                >
                  Clear All
                </AsyncActionButton>
              )}
            </Flex>
            {alerts.map((alert) => (
              <NotificationMenuItem
                key={alert.id}
                alert={alert}
                refetch={refetchNotifications}
              />
            ))}
          </Box>
        )}
      </MenuList>
    </Menu>
  )
}

export default NotificationMenu
