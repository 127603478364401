import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface SidebarState {
  isOpen: boolean
}

const initialState: SidebarState = {
  isOpen: true,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSidebarClosed: (state) => {
      state.isOpen = false
    },
    setSidebarOpen: (state) => {
      state.isOpen = true
    },
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen
    },
  },
})

export const { setSidebarClosed, setSidebarOpen, toggleSidebar } =
  sidebarSlice.actions

export const selectSidebarIsOpen = (state: RootState) =>
  state.sidebarState.isOpen

export default sidebarSlice.reducer
